import { FilledInputProps, InputProps, OutlinedInputProps, TextField } from '@mui/material';
import { CSSProperties, HTMLInputTypeAttribute } from 'react';

type Props = {
  label: string;
  value: string;
  maxLength?: number;
  exibirMaxLength?: boolean;
  onChange: (value: string) => void;
  variant?: 'outlined' | 'standard' | 'filled';
  type?: HTMLInputTypeAttribute;
  style?: CSSProperties;
  fullWidth?: boolean;
  minRows?: number;
  disabled?: boolean;
  InputProps?:
    | Partial<OutlinedInputProps>
    | Partial<InputProps>
    | Partial<FilledInputProps>
    | undefined;
};

const TextFieldGeneric: React.FC<Props> = (props) => {
  const {
    label,
    value,
    maxLength,
    variant,
    type,
    onChange,
    style,
    fullWidth,
    minRows,
    disabled,
    InputProps,
    exibirMaxLength
  } = props;

  let newVariant = variant;
  if (!newVariant) {
    newVariant = 'outlined';
  }

  let newValue = value;
  if (!newValue) {
    newValue = '';
  }
  return (
    <div>
      <TextField
        id="standard-basic"
        label={label}
        variant={newVariant}
        value={newValue}
        multiline
        type={type}
        disabled={disabled}
        minRows={minRows || 0}
        style={style}
        fullWidth={fullWidth === false ? false : true}
        onChange={(event) => {
          if (maxLength && event.target.value.length >= maxLength) {
            return;
          }
          onChange(event.target.value);
        }}
        InputProps={InputProps}
      />

      {maxLength && exibirMaxLength && (
        <div
          style={{
            textAlign: 'right',
            fontSize: '12px',
            color: value.length + 1 >= maxLength ? 'red' : 'inherit'
          }}
        >
          {value.length + 1} / {maxLength}
        </div>
      )}
    </div>
  );
};

export default TextFieldGeneric;
