import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import CARRINHO_GIF from '../../assets/CARRINHO_GIF.gif';

const TituloCarrinhoPedido: React.FC = () => {
  const moveRight = keyframes`
    0% { transform: translateX(0%); }
    100% { transform: translateX(1000%); }
  `;

  const [mostrarTexto, setMostrarTexto] = useState(false);

  const ImgAnimada = styled.img`
    height: 40px;
    width: 40px;
    object-fit: contain;
    animation: ${moveRight} 2s ease-in-out;
    animation-fill-mode: forwards;
  `;

  const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

  const TypographyAnimado = styled(Typography)`
    padding: 10px;
    font-weight: bold;
    text-align: center;
    font-size: 18px;
    position: relative;
    z-index: 1;
    animation: ${fadeInUp} 0.8s ease-out;
  `;

  const Container = styled.div`
    position: relative;
    overflow: hidden;
  `;

  return (
    <Container>
      {!mostrarTexto && (
        <ImgAnimada src={CARRINHO_GIF} onAnimationEnd={() => setMostrarTexto(true)} />
      )}

      {mostrarTexto && <TypographyAnimado>Meu carrinho</TypographyAnimado>}
    </Container>
  );
};

export default TituloCarrinhoPedido;
