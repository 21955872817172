import { Grid, InputAdornment, Pagination, TextField, Typography } from '@mui/material';
import { SetStateAction, useEffect, useState } from 'react';
import apiProdutoLV from '../../api/apiProdutoLV';
import ProdutoLV from '../../Entity/ProdutoLV';
import Container from '../../Components/generic/Container';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import SearchIcon from '@mui/icons-material/Search';
import { FindManyReq } from '../../Entity/TypeormTypes';
import Tooltip from '@mui/material/Tooltip';
import { ProdutosDisponiveisEdCliente } from '../../types/NovoPedidoTypes';
import ModalAdicionarProduto from './ModalAdicionarProduto';
import { useSessao } from '../../redux/sessao/sessaoHooks';
import apiItensCarrinho from '../../api/apiItensCarrinho';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ProdutoCard from './ProdutoCard';
import { toast } from 'react-toastify';
import { mostrarToast } from '../../helper';

const NovoPedidoCliente: React.FC = () => {
  const classes = useStyles();

  const [sessao] = useSessao();

  const [produtosDisponiveis, setProdutosDisponiveis] = useState<ProdutosDisponiveisEdCliente[]>(
    []
  );
  const [pagina, setPagina] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(1);
  const [query, setQuery] = useState<FindManyReq<ProdutoLV> | null>(null);
  const [loading, setLoading] = useState(true);
  const [pesquisa, setPesquisa] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [showScrollTopButton, setShowScrollTopButton] = useState(false);
  const [produtoFlashCard, setProdutoFlashCard] = useState<string | null>('');
  const [produtoSelecionado, setProdutoSelecionado] = useState<ProdutosDisponiveisEdCliente>();

  const produtosPorPagina = 20;
  const isMobile = window.innerWidth <= 600;
  const corEmpresa = sessao.empresa?.corPrincipal ?? '#2196f3';
  const codigoCliente = sessao?.clienteUsuario?.codigoCliente;
  const utilizaEstoqueDav = sessao?.empresa?.utilizaEstoqueDav;
  const verificaEstoque = sessao.empresa?.verificaEstoque;

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      buscar();
    }
  };

  const buscar = async () => {
    try {
      setPagina(1);
      setProdutosDisponiveis([]);
      const query: FindManyReq<ProdutoLV> = {};
      query.where = { loja: 'EDCLIENTE' };
      query.relations = ['produto'];

      setQuery(query);
      await obterProdutos();
    } catch (error) {
      console.error('Error ao buscar produtos', error);
    }
  };

  const obterProdutos = async () => {
    try {
      setLoading(true);
      if (!query) return;
      query.skip = (pagina - 1) * produtosPorPagina;
      query.take = produtosPorPagina;
      query.relations = ['produto'];
      query.where = {
        loja: 'EDCLIENTE'
      };

      const currentURL = window.location.href;

      const res = await apiProdutoLV.getMany(query, pesquisa, currentURL);
      const produtosLv = res.produtos;
      const paginasTotal = (res.totalResultados / produtosPorPagina).toFixed(0);

      setTotalPaginas(Number(paginasTotal));

      const produtosCarrinho = await apiItensCarrinho.getMany({
        where: { codigo_cliente: codigoCliente }
      });

      if (produtosLv && produtosLv.length > 0) {
        const produtosLvReduzidoOCarrinho = produtosLv.map((produto) => {
          const quantidadeCarrinho = produtosCarrinho
            .filter((item) => item.codigo_produto === produto.codigo)
            .reduce((total, produto) => (total += produto.quantidade), 0);

          produto.quantidadeEstoque = produto.quantidadeEstoque - (quantidadeCarrinho ?? 0);

          return produto;
        });

        setProdutosDisponiveis(produtosLvReduzidoOCarrinho);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const alterarQuantidadePedida = (codigo: string, novoValor: number, quantidadeMax: number) => {
    if (novoValor < 0) {
      return;
    }

    if (verificaEstoque && novoValor > quantidadeMax) {
      mostrarToast('Estoque máximo atingido ou insuficiente!', 'warning');

      return;
    }

    setProdutosDisponiveis((prevProdutos) => {
      if (prevProdutos && novoValor >= 0) {
        return prevProdutos.map((produto) =>
          produto.codigo === codigo ? { ...produto, quantidadePedida: novoValor } : produto
        );
      }
      return prevProdutos;
    });
  };

  const adicionarProdutoAoCarrinho = async (produto: ProdutosDisponiveisEdCliente) => {
    try {
      if (produto.quantidadePedida <= 0) {
        mostrarToast('Quantidade inválida!', 'warning');
        return;
      }

      if (verificaEstoque) {
        const res = await apiItensCarrinho.getMany({
          where: { codigo_produto: produto.codigo, codigo_cliente: codigoCliente },
          relations: ['produto']
        });

        if (res) {
          const quantidadeCarrinhoTotal = res?.reduce((soma, item) => (soma += item.quantidade), 0);
          const quantidadePedida = produto.quantidadePedida;
          const quantidadeEstoqueAtual = utilizaEstoqueDav
            ? res[0]?.produto.estoques[0].quantidadeReal
            : res[0]?.produto.estoques[0].quantidadeFiscal;

          if (quantidadeCarrinhoTotal + quantidadePedida > quantidadeEstoqueAtual) {
            mostrarToast('Estoque máximo atingido ou insuficiente!', 'warning');
            return;
          }
        }
      }

      const produtoJaAdicionado = await apiItensCarrinho.getOne({
        where: {
          codigo_cliente: codigoCliente,
          codigo_produto: produto?.codigo
        }
      });

      if (produtoJaAdicionado) {
        const novaQuantidade = produtoJaAdicionado.quantidade + produto?.quantidadePedida;
        await apiItensCarrinho.update(
          { id: produtoJaAdicionado.id },
          { quantidade: novaQuantidade }
        );
      } else {
        await apiItensCarrinho.insert({
          codigo_cliente: codigoCliente,
          codigo_produto: produto?.codigo,
          quantidade: produto?.quantidadePedida
        });
      }

      mostrarToast('Produto adicionado ao carrinho!', 'success', 'bottom-right');

      setProdutoFlashCard(produto.codigo);

      setOpenModal(false);

      await atualizarEstoque(produto);
    } catch (err: any) {
      toast.error(err.response.data);
      console.error(err);
    }
  };

  const atualizarEstoque = async (produto: ProdutosDisponiveisEdCliente) => {
    try {
      const produtosCarrinho = await apiItensCarrinho.getMany({
        where: { codigo_cliente: codigoCliente, codigo_produto: produto.codigo }
      });

      const quantidadeCarrinho = produtosCarrinho.reduce(
        (total, produto) => (total += produto.quantidade),
        0
      );

      const novaQuantidade = produto.quantidadeEstoqueAtual - quantidadeCarrinho;

      setProdutosDisponiveis((prevProdutos) => {
        return prevProdutos.map((item) =>
          item.codigo === produto.codigo
            ? { ...item, quantidadeEstoque: novaQuantidade, quantidadePedida: 0 }
            : item
        );
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (query) {
      obterProdutos();
    }
     
  }, [query, pagina]);

  const handleScroll = () => {
    if (document.documentElement.scrollTop > 200) {
      setShowScrollTopButton(true);
    } else {
      setShowScrollTopButton(false);
    }
  };

  useEffect(() => {
    buscar();
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
     
  }, []);

  const alterarPagina = (event: any, value: SetStateAction<number>) => {
    setPagina(value);
  };

  return (
    <Container loading={loading}>
      <>
        <Grid container spacing={3}>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <TextField
              label=""
              value={pesquisa}
              onChange={(e) => setPesquisa(e.target.value)}
              onKeyDown={handleKeyDown}
              sx={{ m: 1, width: { xs: '100%', md: '50%' } }}
              placeholder={
                isMobile ? 'Pesquisar' : 'Pesquise o produto pela descrição, código ou referência.'
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title="Pesquisar" arrow>
                      <IconButton onClick={buscar}>
                        <SearchIcon className={classes.iconButton} />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={isMobile ? 'body2' : 'h5'} gutterBottom>
              {produtosDisponiveis.length > 0
                ? 'Produtos disponíveis'
                : loading
                ? 'Buscando produtos...,'
                : 'Nenhum produto disponível'}
            </Typography>
          </Grid>
          {produtosDisponiveis.map((produto) => (
            <ProdutoCard
              key={produto.codigo}
              produto={produto}
              produtoFlashCard={produtoFlashCard}
              alterarQuantidadePedida={alterarQuantidadePedida}
              adicionarProdutoAoCarrinho={adicionarProdutoAoCarrinho}
              setOpenModal={setOpenModal}
              setProdutoSelecionado={setProdutoSelecionado}
              atualizarEstoque={atualizarEstoque}
            />
          ))}
        </Grid>

        <Grid container justifyContent="center" style={{ marginTop: '50px' }}>
          <Grid item>
            {isMobile ? (
              <Pagination
                count={totalPaginas}
                page={pagina}
                onChange={alterarPagina}
                hideNextButton
                hidePrevButton
              />
            ) : (
              <Pagination count={totalPaginas} page={pagina} onChange={alterarPagina} />
            )}
          </Grid>
        </Grid>

        {showScrollTopButton && (
          <Tooltip title="Ir para o topo" arrow>
            <IconButton onClick={scrollToTop} className={classes.scrollTopButton} color="primary">
              <ArrowUpwardIcon style={{ color: corEmpresa }} />
            </IconButton>
          </Tooltip>
        )}

        {produtoSelecionado && (
          <ModalAdicionarProduto
            produto={produtoSelecionado}
            onClose={async () => {
              const position = window.scrollY;
              // await buscar();
              setOpenModal(false);
              window.scrollTo(0, position);
              setProdutoFlashCard('');
            }}
            open={openModal}
            adicionarProdutoAoCarrinho={adicionarProdutoAoCarrinho}
          />
        )}
      </>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  iconButton: {
    cursor: 'pointer'
  },
  scrollTopButton: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 1000,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2]
  }
}));

export default NovoPedidoCliente;
