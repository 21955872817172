import { Button, Collapse, Grid, IconButton, Typography } from '@mui/material';
import { useSessao } from '../../redux/sessao/sessaoHooks';
import TextFieldGeneric from '../../Components/generic/TextFieldGeneric';
import Info from '../../Components/generic/Info';
import { useState } from 'react';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import SaveIcon from '@mui/icons-material/Save';
import { toast } from 'react-toastify';
import clienteApi from '../../api/apiCliente';
import { Cliente } from '../../Entity/Cliente';
import { loginAction } from '../../redux/sessao/sessaoActions';
import { useDispatch } from 'react-redux';
import Container from '../../Components/generic/Container';

const PerfilPage: React.FC = () => {
  const [sessao] = useSessao();
  const clienteLogado = sessao.cliente;

  const [expandirEnderecoEntrega, setExpandirEnderecoEntrega] = useState(false);
  const [expandirExpandirCobrança, setExpandirEnderecoCobrança] = useState(false);
  const [cliente, setCliente] = useState<Cliente | null>(clienteLogado);
  const dispatch = useDispatch();

  const salvar = async () => {
    try {
      if (!cliente) return;
      const res = await clienteApi.update({ codigo: cliente.codigo }, cliente);
      toast.success('Informações do perfil atualizadas!');
      const action = loginAction({ ...sessao, cliente: res });
      dispatch(action);
    } catch (erro: any) {
      console.log('Erro ao salvar informações do perfil', erro);
      toast.error('Erro ao salvar informações do perfil');
    }
  };

  return (
    <Container page>
      <Grid container spacing={2}>
        {cliente && (
          <>
            <Grid item xs={12}>
              <Typography variant="h5" component={'div'}>
                Configurações de perfil
              </Typography>

              {!sessao.empresa?.clientePodeAlterarCadastro && (
                <Grid item xs={12}>
                  <h3>Caso deseje alterar alguma informação, contate seu representante.</h3>
                </Grid>
              )}
            </Grid>

            <Grid item xs={12}>
              <Info label="" fontSize={22}>
                <>Dados pessoais</>
              </Info>
            </Grid>

            <Grid item xs={12}>
              <TextFieldGeneric
                label={'Nome'}
                value={cliente?.nome}
                disabled={!sessao.empresa?.clientePodeAlterarCadastro}
                onChange={(value) => {
                  setCliente({ ...cliente, nome: value });
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextFieldGeneric
                label={'Apelido'}
                value={cliente.nomeFantasia}
                disabled={!sessao.empresa?.clientePodeAlterarCadastro}
                onChange={(value) => {
                  setCliente({ ...cliente, nomeFantasia: value });
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextFieldGeneric
                label={'CPF'}
                value={cliente.cpf}
                disabled
                onChange={(value) => {
                  setCliente({ ...cliente, cpf: value });
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextFieldGeneric
                label={'CNPJ'}
                value={cliente.cnpj}
                disabled
                onChange={(value) => {
                  setCliente({ ...cliente, cnpj: value });
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextFieldGeneric
                label={'DDD'}
                value={cliente.dddCelular}
                disabled={!sessao.empresa?.clientePodeAlterarCadastro}
                onChange={(value) => {
                  setCliente({ ...cliente, dddCelular: value });
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextFieldGeneric
                label={'Celular'}
                value={cliente.celular}
                disabled={!sessao.empresa?.clientePodeAlterarCadastro}
                onChange={(value) => {
                  setCliente({ ...cliente, celular: value });
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextFieldGeneric
                label={'Data de nascimento'}
                value={cliente.dataNascimento}
                disabled={!sessao.empresa?.clientePodeAlterarCadastro}
                onChange={(value) => {
                  setCliente({ ...cliente, dataNascimento: value });
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Info label="" fontSize={22}>
                <>Endereço</>
              </Info>
            </Grid>

            <Grid item xs={4}>
              <TextFieldGeneric
                label={'CEP'}
                value={''}
                disabled={!sessao.empresa?.clientePodeAlterarCadastro}
                onChange={(value) => {
                  setCliente({ ...cliente, cpf: value });
                }}
              />
            </Grid>

            <Grid item xs={8}>
              <TextFieldGeneric
                label={'Endereço'}
                value={''}
                disabled={!sessao.empresa?.clientePodeAlterarCadastro}
                onChange={(value) => {
                  setCliente({ ...cliente, cpf: value });
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <TextFieldGeneric
                label={'Cidade'}
                value={''}
                disabled={!sessao.empresa?.clientePodeAlterarCadastro}
                onChange={(value) => {
                  setCliente({ ...cliente, cpf: value });
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <TextFieldGeneric
                label={'N°'}
                value={''}
                disabled={!sessao.empresa?.clientePodeAlterarCadastro}
                onChange={(value) => {
                  setCliente({ ...cliente, cpf: value });
                }}
              />
            </Grid>

            <Grid item xs={5}>
              <TextFieldGeneric
                label={'Bairro'}
                value={''}
                disabled={!sessao.empresa?.clientePodeAlterarCadastro}
                onChange={(value) => {
                  setCliente({ ...cliente, cpf: value });
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <IconButton
                onClick={() => {
                  setExpandirEnderecoCobrança(!expandirExpandirCobrança);
                }}
              >
                {expandirExpandirCobrança ? (
                  <KeyboardArrowUpOutlinedIcon />
                ) : (
                  <KeyboardArrowDownOutlinedIcon />
                )}

                <Typography component={'h2'} fontSize={22}>
                  Endereço de cobrança
                </Typography>
              </IconButton>

              <Collapse in={expandirExpandirCobrança}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <TextFieldGeneric
                      label={'CEP'}
                      value={''}
                      disabled={!sessao.empresa?.clientePodeAlterarCadastro}
                      onChange={(value) => {
                        setCliente({ ...cliente, cpf: value });
                      }}
                    />
                  </Grid>

                  <Grid item xs={8}>
                    <TextFieldGeneric
                      label={'Endereço'}
                      value={''}
                      disabled={!sessao.empresa?.clientePodeAlterarCadastro}
                      onChange={(value) => {
                        setCliente({ ...cliente, cpf: value });
                      }}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <TextFieldGeneric
                      label={'Cidade'}
                      value={''}
                      disabled={!sessao.empresa?.clientePodeAlterarCadastro}
                      onChange={(value) => {
                        setCliente({ ...cliente, cpf: value });
                      }}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <TextFieldGeneric
                      label={'N°'}
                      value={''}
                      disabled={!sessao.empresa?.clientePodeAlterarCadastro}
                      onChange={(value) => {
                        setCliente({ ...cliente, cpf: value });
                      }}
                    />
                  </Grid>

                  <Grid item xs={5}>
                    <TextFieldGeneric
                      label={'Bairro'}
                      value={''}
                      disabled={!sessao.empresa?.clientePodeAlterarCadastro}
                      onChange={(value) => {
                        setCliente({ ...cliente, cpf: value });
                      }}
                    />
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>
            <Grid item xs={12}>
              <IconButton
                onClick={() => {
                  setExpandirEnderecoEntrega(!expandirEnderecoEntrega);
                }}
              >
                {expandirEnderecoEntrega ? (
                  <KeyboardArrowUpOutlinedIcon />
                ) : (
                  <KeyboardArrowDownOutlinedIcon />
                )}

                <Typography component={'h2'} fontSize={22}>
                  Endereço de entrega
                </Typography>
              </IconButton>

              <Collapse in={expandirEnderecoEntrega}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <TextFieldGeneric
                      disabled={!sessao.empresa?.clientePodeAlterarCadastro}
                      label={'CEP'}
                      value={''}
                      onChange={() => {}}
                    />
                  </Grid>

                  <Grid item xs={8}>
                    <TextFieldGeneric
                      disabled={!sessao.empresa?.clientePodeAlterarCadastro}
                      label={'Endereço'}
                      value={''}
                      onChange={() => {}}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <TextFieldGeneric
                      disabled={!sessao.empresa?.clientePodeAlterarCadastro}
                      label={'Cidade'}
                      value={''}
                      onChange={() => {}}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <TextFieldGeneric
                      disabled={!sessao.empresa?.clientePodeAlterarCadastro}
                      label={'N°'}
                      value={''}
                      onChange={() => {}}
                    />
                  </Grid>

                  <Grid item xs={5}>
                    <TextFieldGeneric
                      disabled={!sessao.empresa?.clientePodeAlterarCadastro}
                      label={'Bairro'}
                      value={''}
                      onChange={() => {}}
                    />
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>

            {sessao.empresa?.clientePodeAlterarCadastro && (
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Button
                  variant="contained"
                  onClick={() => {
                    salvar();
                  }}
                  startIcon={<SaveIcon />}
                >
                  Salvar
                </Button>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Container>
  );
};

export default PerfilPage;
