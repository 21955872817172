import { Pedido } from '../../Entity/Pedido';
import { formatarDinheiro } from '../../helper';
import { Grid, IconButton, Paper, Typography } from '@mui/material';
import { DataGrid, GridColDef, ptBR } from '@mui/x-data-grid';
import ModalGeneric from '../../Components/generic/ModalGeneric';
import { useEffect, useState } from 'react';
import pedidoApi from '../../api/apiPedido';
import { ItemPedidoCompletoType, PedidoCompletoEDClienteType } from '../../types/PedidoTypes';
import { toast } from 'react-toastify';
import LinearProgress from '@mui/joy/LinearProgress';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Info from '../../Components/generic/Info';
import ResumoPagamento from './ResumoPagamentoPedidoCliente';

type Props = {
  pedidoSelecionado: Pedido;
  onClose: () => void;
  open: boolean;
};

const ModalDetalhesPedido: React.FC<Props> = (props) => {
  const { pedidoSelecionado, onClose, open } = props;

  const [items, setItems] = useState<ItemPedidoCompletoType[]>([]);
  const [pedidoCompleto, setPedidoCompleto] = useState<PedidoCompletoEDClienteType | null>(null);
  const [loading, setLoading] = useState(true);
  const [imprimindo, setImprimindo] = useState(false);
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const columns: GridColDef[] = [
    {
      field: 'produto',
      headerName: 'Produto',
      width: 250,
      align: 'left',
      editable: false,
      renderCell: (row) => {
        const item = row.row as ItemPedidoCompletoType;
        return <span style={{ padding: '10px' }}>{item.descricao}</span>;
      }
    },
    {
      field: 'quantidade',
      headerName: 'Qtd.',
      width: 50,
      align: 'left',
      editable: false,
      renderCell: (row) => {
        const item = row.row as ItemPedidoCompletoType;
        return <span style={{ padding: '10px' }}>{item.quantidade} </span>;
      }
    },
    {
      field: 'precoBruto',
      headerName: 'Pr. Bruto',
      width: 100,
      align: 'left',
      editable: false,
      renderCell: (row) => {
        const item = row.row as ItemPedidoCompletoType;
        return <span style={{ padding: '10px' }}>{formatarDinheiro(item.preco)}</span>;
      }
    },

    {
      field: 'precoLiquido',
      headerName: 'Subtotal',
      width: 100,
      align: 'left',
      editable: false,
      renderCell: (row) => {
        const item = row.row as ItemPedidoCompletoType;
        return (
          <span style={{ padding: '10px' }}> {formatarDinheiro(item.preco * item.quantidade)}</span>
        );
      }
    }
  ];

  const getPedido = async () => {
    setLoading(true);
    try {
      const res = await pedidoApi.getOneCompleto(pedidoSelecionado.numeroPedido, true, '001');
      setPedidoCompleto(res);

      setItems(res.produtos);
    } catch (err: any) {
      toast.error('Ocorreu um erro ao buscar pedido.');
      console.log(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    getPedido();
     
  }, []);

  const baixarDav = async () => {
    setImprimindo(true);
    try {
      if (!pedidoCompleto?.numeroPedido) return;
      await pedidoApi.downloadDav(pedidoCompleto?.numeroPedido);
      setImprimindo(false);
    } catch (erro: any) {
      setImprimindo(false);
      toast.error('Aconteceu um erro ao imprimir o pedido');
    }
  };

  return (
    <ModalGeneric
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Typography component={'div'} variant="h4">
            Pedido #{pedidoSelecionado.numeroPedido}
          </Typography>
        </Grid>

        <Grid item xs={12} md={12}>
          <Typography>{pedidoCompleto?.nomeCfgPedido}</Typography>
        </Grid>

        {pedidoCompleto?.numeroNf !== ' ' && pedidoCompleto?.serieNf !== ' ' && (
          <Grid item xs={12} md={12}>
            <Typography>
              Nota Fiscal N° {Number(pedidoCompleto?.numeroNf)}/{pedidoCompleto?.serieNf}
            </Typography>
          </Grid>
        )}

        {loading && (
          <Grid item xs={12}>
            <Typography component={'div'} variant="h6" style={{ textAlign: 'center' }}>
              Carregando detalhes . . .
            </Typography>
            <LinearProgress color="primary" size="lg" value={50} variant="soft" />
          </Grid>
        )}

        {imprimindo && (
          <Grid item xs={12}>
            <Typography component={'div'} variant="h6" style={{ textAlign: 'center' }}>
              Carregando detalhes . . .
            </Typography>
            <LinearProgress color="primary" size="lg" value={50} variant="soft" />
          </Grid>
        )}

        {!loading && (
          <>
            {!isMobile && (
              <Grid item xs={12} style={{ textAlign: 'left' }}>
                <IconButton
                  onClick={() => {
                    baixarDav();
                  }}
                >
                  <PictureAsPdfIcon color="error" />{' '}
                  <span style={{ margin: '5px' }}>Imprimir pedido</span>
                </IconButton>
              </Grid>
            )}

            <Grid item xs={4}>
              <Info label={'Valor Tot.'} fontSize={16}>
                R$ {formatarDinheiro(pedidoCompleto?.totalBruto ?? 0)}
              </Info>
            </Grid>

            <Grid item xs={4}>
              <Info label={'Valor Desc'} fontSize={16}>
                R$ {formatarDinheiro(pedidoCompleto?.descontoGeral ?? 0)}
              </Info>
            </Grid>

            <Grid item xs={4}>
              <Info label={'Valor Liq.'} fontSize={16}>
                R${' '}
                {formatarDinheiro(
                  (pedidoCompleto?.totalBruto ?? 0) - (pedidoCompleto?.descontoGeral ?? 0)
                )}
              </Info>
            </Grid>

            <Grid item xs={12}>
              <Info label={'Vendedor'} fontSize={16}>
                {pedidoCompleto?.nomeVendedor}
              </Info>
            </Grid>
          </>
        )}

        {items.length > 0 && (
          <>
            <Grid item xs={12}>
              <Typography component={'div'} variant="h6">
                Itens do Pedido
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{ display: { xs: 'flex', md: 'none' } }}
              style={{ flexDirection: 'column' }}
            >
              {items.map((item) => (
                <Paper style={{ margin: '8px 0px', padding: '12px' }} key={item.codigo}>
                  <Grid container height={'100%'}>
                    <Grid item xs={12} textAlign={'left'}>
                      <Typography fontWeight={'bold'} variant="h6">
                        {item.descricao}
                      </Typography>
                    </Grid>

                    <Grid item xs={4} marginTop={'10px'}>
                      Qnt: <Typography>{item.quantidade}</Typography>
                    </Grid>

                    <Grid item xs={4} marginTop={'10px'}>
                      R$ <Typography>{formatarDinheiro(item.preco)}</Typography>
                    </Grid>

                    <Grid item xs={4} marginTop={'10px'}>
                      <Typography>
                        <div>TOTAL</div>
                        {formatarDinheiro(item.preco * item.quantidade)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              ))}
            </Grid>

            <Grid item xs={12}>
              <DataGrid
                sx={{ display: { xs: 'none', md: 'flex' } }}
                rows={items}
                columns={columns}
                pageSizeOptions={[5]}
                rowCount={items.length}
                getRowHeight={() => 'auto'}
                getRowId={(row) => row.codigo}
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <ResumoPagamento pedido={pedidoCompleto} />
        </Grid>
      </Grid>
    </ModalGeneric>
  );
};

export default ModalDetalhesPedido;
