import { ReactNode } from 'react';
import { Route } from 'react-router-dom';

import { ItemMenuType } from './ItemMenuType';
import PageWrapper from './PageWraper';
import { itensMenu } from './ItensMenu';

const generateRoute = (routes: ItemMenuType[]): ReactNode => {
  return routes.map((route, index) =>
    route.index ? (
      <>
        <Route
          index
          path={route.path}
          element={<PageWrapper state={route.key}>{route.componente}</PageWrapper>}
          key={index}
        />
      </>
    ) : (
      <>
        <Route
          path={route.path}
          element={
            <PageWrapper state={route.child ? undefined : route.key}>
              {route.componente}
            </PageWrapper>
          }
          key={index}
        >
          {route.child && generateRoute(route.child)}
        </Route>
      </>
    )
  );
};

export const routes: ReactNode = generateRoute(itensMenu);
