import { Box, ButtonBase, Tooltip } from '@material-ui/core';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import React from 'react';
import EdNumberField from './EdNumberField';

type Props = {
  valor: number;
  setValor: (x: number) => void;
  autoFocus?: boolean;
};

const QuantidadeField: React.FC<Props> = (props) => {
  const { valor, setValor, autoFocus } = props;
  return (
    <Box display="flex" width={1} justifyContent="space-around" style={{ marginBottom: 10 }}>
      <ButtonBase onClick={() => setValor(valor - 1)}>
        <Tooltip title="Remover" arrow>
          <RemoveIcon />
        </Tooltip>
      </ButtonBase>
      <EdNumberField
        value={valor}
        label=""
        variant={'standard'}
        textAlign={'center'}
        type={'number'}
        onChange={setValor}
        onBlur={() => setValor(valor)}
        autoSelect
        autoFocus={autoFocus}
      />
      <ButtonBase onClick={() => setValor(valor + 1)}>
        <Tooltip title="Adicionar" arrow>
          <AddIcon />
        </Tooltip>
      </ButtonBase>
    </Box>
  );
};

export default QuantidadeField;
