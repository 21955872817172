// import PropTypes from 'prop-types'
import { createStyles, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import apiEmpresa from '../../api/apiEmpresa';
import { DadosPublicosEmpresa } from '../../Entity/Empresa';
import Loader from '../../Components/generic/Loader';

const PoliticasPrivacidadePage: React.FC = () => {
  const classes = useStyles();

  const [empresa, setEmpresa] = useState<DadosPublicosEmpresa | undefined>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getDadosEmpresa = async () => {
      setLoading(true);
      try {
        const res = await apiEmpresa.getDadosPublicosEmpresa();
        setEmpresa(res);
      } catch (erro: any) {
        console.error(erro);
      }
      setLoading(false);
    };

    getDadosEmpresa();
  }, []);

  if (loading)
    return (
      <div className={classes.content}>
        <Loader />
      </div>
    );

  const nomeEmpresa = empresa?.nome ?? process.env.REACT_APP_COMPANY;

  return (
    <div className={classes.form}>
      <h1>Política de Privacidade - {nomeEmpresa}</h1>
      <p>
        A <strong>{nomeEmpresa}</strong> valoriza a sua privacidade e se compromete a proteger os
        dados pessoais dos usuários do nosso aplicativo, disponível nas plataformas Android, iOS e
        Web. Esta política explica como coletamos, usamos, armazenamos e protegemos seus dados.
      </p>

      <h2>1. Informações Coletadas</h2>
      <p>
        Coletamos os seguintes dados pessoais fornecidos diretamente pelos usuários através de
        formulários e cadastros:
      </p>
      <ul>
        <li>Nome</li>
        <li>Endereço</li>
        <li>Telefone</li>
        <li>E-mail</li>
      </ul>
      <p>
        Essas informações são necessárias para a criação de contas, processamento de pedidos e
        comunicação com nossos clientes.
      </p>

      <h2>2. Uso de Dados</h2>
      <p>As informações pessoais fornecidas pelos usuários são utilizadas exclusivamente para:</p>
      <ul>
        <li>Processar pedidos realizados através do app</li>
        <li>Manter o histórico de compras</li>
        <li>Facilitar a comunicação entre a {nomeEmpresa} e o cliente</li>
      </ul>

      <h2>3. Compartilhamento de Dados</h2>
      <p>
        A {nomeEmpresa} <strong>não compartilha</strong> as informações dos usuários com terceiros.
        Os dados são mantidos de forma confidencial e utilizados apenas para os fins descritos nesta
        política.
      </p>

      <h2>4. Armazenamento de Dados</h2>
      <p>
        Os dados dos usuários são armazenados em nossos servidores de forma segura e criptografada
        por, pelo menos, <strong>5 anos</strong>. Após esse período, os dados poderão ser excluídos,
        a menos que haja uma obrigação legal de mantê-los por um período maior.
      </p>

      <h2>5. Solicitação de Exclusão de Dados</h2>
      <p>
        Os usuários têm o direito de solicitar a exclusão de seus dados pessoais a qualquer momento.
        Para fazer essa solicitação, entre em contato conosco através do link:{' '}
        <a href="https://edilsystem.com.br/site/fale-conosco/">
          https://edilsystem.com.br/site/fale-conosco/
        </a>
        .
      </p>

      <h2>6. Cookies e Tecnologias de Rastreamento</h2>
      <p>
        Nosso aplicativo <strong>não</strong> utiliza cookies ou quaisquer outras tecnologias de
        rastreamento.
      </p>

      <h2>7. Integrações com Serviços de Terceiros</h2>
      <p>
        O app {nomeEmpresa} utiliza os seguintes serviços de terceiros para aprimorar a experiência
        do usuário:
      </p>
      <ul>
        <li>
          <strong>Google Analytics</strong>: para análise de uso e performance do app.
        </li>
        <li>
          <strong>Firebase</strong>: para autenticação e armazenamento de dados.
        </li>
      </ul>
      <p>
        Esses serviços podem coletar dados anônimos sobre o uso do app. Para mais informações sobre
        suas políticas de privacidade, consulte:
      </p>
      <ul>
        <li>
          <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">
            Política de Privacidade do Google Analytics
          </a>
        </li>
        <li>
          <a href="https://firebase.google.com/support/privacy" target="_blank" rel="noreferrer">
            Política de Privacidade do Firebase
          </a>
        </li>
      </ul>

      <h2>8. Proteção dos Dados</h2>
      <p>
        Implementamos medidas de segurança, como <strong>criptografia</strong> e{' '}
        <strong>acesso restrito</strong>, para proteger os dados dos usuários contra acesso não
        autorizado, alteração ou divulgação.
      </p>

      <h2>9. Alterações na Política de Privacidade</h2>
      <p>
        Podemos atualizar esta política de tempos em tempos para refletir mudanças nos nossos
        processos ou em requisitos legais. Notificaremos os usuários sobre quaisquer alterações
        significativas por meio do aplicativo ou do nosso site.
      </p>

      <h2>10. Contato</h2>
      <p>
        Em caso de dúvidas sobre esta Política de Privacidade, entre em contato conosco através do
        link:{' '}
        <a href="https://edilsystem.com.br/site/fale-conosco/">
          https://edilsystem.com.br/site/fale-conosco/
        </a>
        .
      </p>
    </div>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    content: {
      display: 'flex',
      color: '#fff',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%'
    },
    form: {
      // justify
      background: '#fff',
      color: '#000',
      padding: '20px 30px 20px 30px',
      margin: '50px 0px 0px 0px',
      minWidth: '310px',
      minHeight: '380px',
      // flex: "1",,
      display: 'flex',
      flexDirection: 'column'

      // marginBottom: "30px"
      // height: 'auto'
    },
    logo: {
      width: '170px',
      marginBottom: '40px'
    },
    button: {
      marginTop: '30px'
    },
    input: {
      color: '#fff',
      borderColor: '#fff',
      minWidth: '250px',
      marginBottom: '30px'
    }
  })
);

export default PoliticasPrivacidadePage;
