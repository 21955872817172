import { ListItemButton, ListItemIcon } from '@mui/material';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootAppState } from '../../redux/store';
import { ItemMenuType } from './ItemMenuType';

type Props = {
  item: ItemMenuType;
};

const SidebarItem = ({ item }: Props) => {
  const { state } = useSelector((state: RootAppState) => state.appState);

  return item && item.path ? (
    <ListItemButton
      component={Link}
      to={item.path}
      sx={{
        '&: hover': {
          backgroundColor: state === item.key ? '#FFFFFF' : 'unset',
          paddingY: '12px',
          paddingX: '24px'
        }
      }}
    >
      <ListItemIcon>{item.label && item.icon}</ListItemIcon>
      {item.label}
    </ListItemButton>
  ) : null;
};

export default SidebarItem;
