import { Card, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import QuantidadeField from '../../Components/generic/QuantidadeField';
import Tooltip from '@mui/material/Tooltip';
import { ProdutosDisponiveisEdCliente } from '../../types/NovoPedidoTypes';
import SEM_FOTO from '../../../src/assets/sem-foto.jpg';
import { useSessao } from '../../redux/sessao/sessaoHooks';
import { isMobile } from '../../helper';

type Props = {
  produto: ProdutosDisponiveisEdCliente;
  produtoFlashCard: string | null;
  alterarQuantidadePedida: (codigo: string, novoValor: number, quantidadeMax: number) => void;
  adicionarProdutoAoCarrinho: (produto: ProdutosDisponiveisEdCliente) => void;
  setOpenModal: (open: boolean) => void;
  setProdutoSelecionado: (produto: ProdutosDisponiveisEdCliente) => void;
  atualizarEstoque: (produto: ProdutosDisponiveisEdCliente) => void;
};

const ProdutoCard: React.FC<Props> = (props) => {
  const classes = useStyles();

  const {
    produto,
    produtoFlashCard,
    alterarQuantidadePedida,
    adicionarProdutoAoCarrinho,
    setOpenModal,
    setProdutoSelecionado,
    atualizarEstoque
  } = props;

  const [sessao] = useSessao();

  const exibirQtdEstoqueDisponivel = sessao.empresa?.exibirQuantidadeEstoqueDisponivel;

  const flashAnimacao = {
    '@keyframes flashCard': {
      '0%': {
        backgroundColor: 'transparent'
      },
      '50%': {
        backgroundColor: '#ccffcc'
      },
      '100%': {
        backgroundColor: 'transparent'
      }
    },
    animation: 'flashCard 1s ease-in-out'
  };

  const mobile = isMobile();

  return (
    <Grid item xs={12} sm={12 / 2} md={12 / 5} key={produto.codigo}>
      <Card
        className={`${classes.card} ${classes.cardHover}`}
        elevation={3}
        onClick={() => setProdutoSelecionado(produto)}
        sx={produtoFlashCard === produto.codigo ? flashAnimacao : {}}
        onMouseEnter={() => {
          if (!mobile && (!produto?.quantidadePedida || produto?.quantidadePedida <= 0)) {
            atualizarEstoque(produto);
          }
        }}
      >
        <CardMedia
          component="img"
          alt={produto.descricao}
          style={{ height: '140px', width: 'auto', objectFit: 'contain' }}
          src={produto.urlImagem === 'SEM_FOTO' ? SEM_FOTO : produto.urlImagem}
          title={produto.descricao}
          onClick={() => setOpenModal(true)}
        />
        <CardContent className={classes.cardContent}>
          <Tooltip title={produto?.descricao}>
            <Typography
              gutterBottom
              variant="body2"
              style={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                WebkitLineClamp: 2,
                lineHeight: '1.5em',
                minHeight: '3em'
              }}
              onClick={() => setOpenModal(true)}
            >
              {produto?.descricao}
            </Typography>
          </Tooltip>

          <Typography
            variant="body1"
            component="p"
            style={{ fontWeight: 'bold', fontSize: '20px' }}
            onClick={() => setOpenModal(true)}
          >
            R$ {produto.preco}
          </Typography>

          {exibirQtdEstoqueDisponivel && produto.quantidadeEstoque >= 0 && (
            <Typography
              variant="body1"
              component="p"
              style={{ fontSize: '12px' }}
              onClick={() => setOpenModal(true)}
            >
              Quantidade disponível: {produto.quantidadeEstoque}
            </Typography>
          )}

          <div className={classes.flexContainer}>
            <QuantidadeField
              valor={produto.quantidadePedida}
              setValor={(novoValor) =>
                alterarQuantidadePedida(produto.codigo, novoValor, produto.quantidadeEstoque)
              }
            />
            <Tooltip title="Adicionar ao carrinho" placement="bottom" arrow>
              <IconButton onClick={async () => await adicionarProdutoAoCarrinho(produto)}>
                <ShoppingCartCheckoutIcon style={{ color: 'green' }} />
              </IconButton>
            </Tooltip>
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    cursor: 'pointer'
  },
  cardHover: {
    '&:hover': {
      transform: 'scale(1.05)',
      transition: 'transform 0.3s ease-in-out'
    }
  },
  cardContent: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px'
  },
  iconButton: {
    cursor: 'pointer'
  },
  scrollTopButton: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 1000,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2]
  }
}));

export default ProdutoCard;
