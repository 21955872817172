import { Grid, Paper, Typography } from '@mui/material';
import { ParcelaPedidoCompletoType, PedidoCompletoEDClienteType } from '../../types/PedidoTypes';
import Info from '../../Components/generic/Info';
import { formatarData, formatarDinheiro, getTipoStatus, parseData } from '../../helper';
import { DataGrid, GridColDef, ptBR } from '@mui/x-data-grid';

type Props = {
  pedido: null | PedidoCompletoEDClienteType;
  imprimindo?: boolean;
};

const ResumoPagamentoPedidoCliente: React.FC<Props> = (props) => {
  const { pedido, imprimindo, ...other } = props;
  if (!pedido) {
    return null;
  }

  const parcelas = pedido.parcelas;

  const columns: GridColDef[] = [
    {
      field: 'parcela',
      headerName: 'Parcela',
      width: 100,
      align: 'left',
      editable: false,
      renderCell: (row) => {
        const item = row.row as ParcelaPedidoCompletoType;
        return <p>{item.parcela + 1}º Parcela</p>;
      }
    },
    {
      field: 'Valor',
      headerName: 'Valor.',
      width: 80,
      align: 'left',
      editable: false,
      renderCell: (row) => {
        const parcela = row.row as ParcelaPedidoCompletoType;
        return `R$${formatarDinheiro(parcela.valor)}`;
      }
    },
    {
      field: 'Tipo/Status',
      headerName: 'Tipo/Status',
      width: 100,
      align: 'left',
      editable: false,
      renderCell: (row) => {
        const contaReceber = row.row;
        return getTipoStatus(contaReceber);
      }
    },

    {
      field: 'vencimento',
      headerName: 'Vencimento',
      width: 100,
      align: 'left',
      editable: false,
      renderCell: (row) => {
        const parcela = row.row as ParcelaPedidoCompletoType;
        return formatarData(parcela.vencimento);
      }
    }
  ];

  return (
    <Grid container spacing={2} {...other}>
      <Grid item xs={12}>
        <h2>Pagamento</h2>
      </Grid>

      <Grid item xs={12}>
        {pedido.condicaoPagamento === '00' && (
          <Info label="TOTAL À VISTA" fontSize={16}>
            R${formatarDinheiro(pedido.totalBruto - pedido.descontoGeral + pedido.acrescimoGeral)}
          </Info>
        )}

        {pedido.condicaoPagamento !== '00' && (
          <Info label="TOTAL" fontSize={16}>
            R${formatarDinheiro(pedido.totalBruto - pedido.descontoGeral + pedido.acrescimoGeral)}
          </Info>
        )}
      </Grid>

      <Grid
        item
        xs={12}
        sx={{ display: { xs: 'flex', md: 'none' } }}
        style={{ flexDirection: 'column' }}
      >
        {parcelas.map((parcela) => (
          <Paper style={{ margin: '8px 0px', padding: '12px' }} key={parcela.parcela}>
            <Grid container height={'100%'}>
              <Grid item xs={10} textAlign={'left'}>
                <Typography fontWeight={'bold'} variant="h6">
                  Vencimento: {parseData(parcela.vencimento)}
                </Typography>
              </Grid>

              <Grid item xs={1}>
                {getTipoStatus(parcela)}
              </Grid>

              <Grid item xs={6} marginTop={'10px'}>
                <Typography>
                  <p>{parcela.parcela + 1}º Parcela</p>
                </Typography>
              </Grid>

              <Grid item xs={6} marginTop={'10px'}>
                <Typography>
                  <div>TOTAL</div>
                  <div>R$ {formatarDinheiro(parcela.valor)}</div>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        ))}
      </Grid>

      {parcelas.length !== 0 && (
        <Grid item xs={12}>
          <DataGrid
            rows={parcelas}
            sx={{ display: { xs: 'none', md: 'flex' } }}
            columns={columns}
            pageSizeOptions={[5]}
            rowCount={parcelas.length}
            getRowHeight={() => 'auto'}
            getRowId={(row) => row.parcela}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default ResumoPagamentoPedidoCliente;
