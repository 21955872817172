import { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import 'dayjs/locale/pt-br';

dayjs.locale('pt-br');
type Props = {
  label: string;
  value: Dayjs;
  setValue: (value: Dayjs) => void;
  minDate?: dayjs.Dayjs;
  disabled?: boolean;
  fullWidth?: boolean;
};

export default function DatePickerGeneric(props: Props) {
  const { value, setValue, label, minDate, disabled, fullWidth } = props;
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        value={value}
        disabled={disabled}
        format="DD/MM/YYYY"
        onChange={(newValue) => {
          if (newValue) setValue(newValue);
        }}
        minDate={minDate}
        slotProps={{ textField: { fullWidth: fullWidth } }}
      />
    </LocalizationProvider>
  );
}
