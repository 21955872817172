import ItensCarrinho from '../Entity/ItensCarrinho';
import { FindManyReq, FindOneReq } from '../Entity/TypeormTypes';
import { ProdutosCarrinhoEdCliente } from '../types/NovoPedidoTypes';
import api from './apiEdCliente';

const PREFIX = 'api';
const ROUTE = 'edCliente/itensCarrinho';

class ApiItensCarrinho {
  async getOne(params: FindOneReq<ItensCarrinho>) {
    const res = await this.getMany({ ...params, take: 1 });
    return res[0] as ItensCarrinho | undefined;
  }

  async getMany(query: FindManyReq<ItensCarrinho>) {
    try {
      const res = await api.post<ItensCarrinho[]>(`/${PREFIX}/${ROUTE}/get`, { query });
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }
  async getManyComMidia(query: FindManyReq<ItensCarrinho>) {
    try {
      const res = await api.post<ProdutosCarrinhoEdCliente[]>(`/${PREFIX}/${ROUTE}/obterItens`, {
        query
      });
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async count(query: FindManyReq<ItensCarrinho>) {
    try {
      const res = await api.post<number>(`/${PREFIX}/${ROUTE}/count`, { query });
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async update(params: Partial<ItensCarrinho>, update: Partial<ItensCarrinho>) {
    try {
      const res = await api.put<ItensCarrinho>(`/${PREFIX}/${ROUTE}/`, { update }, { params });
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async insert(object: Partial<ItensCarrinho>) {
    try {
      const res = await api.post<ItensCarrinho>(`/${PREFIX}/${ROUTE}`, { object });
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async delete(params: Partial<ItensCarrinho>) {
    try {
      const res = await api.delete<ItensCarrinho>(`/${PREFIX}/${ROUTE}/`, { params });
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }
}

const apiItensCarrinho = new ApiItensCarrinho();
export default apiItensCarrinho;
