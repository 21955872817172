import { ReactNode } from 'react';
// import { setAppState } from "../../redux/features/appStateSlice";

type Props = {
  state?: string;
  children: ReactNode;
};

const PageWrapper = (props: Props) => {
  // const dispatch = useDispatch();

  //   useEffect(() => {
  //     if (props.state) {
  //       dispatch(setAppState(props.state));
  //     }
  //   }, [dispatch, props]);

  return <div>{props.children}</div>;
};

export default PageWrapper;
