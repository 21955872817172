import React from 'react';
import LogoEdilSystem from '../../assets/edilsystem-logo.png';

const FooterTelaLogin: React.FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        textAlign: 'center',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <img src={LogoEdilSystem} alt="LogoEdilSystem" width={80} />
    </div>
  );
};

export default FooterTelaLogin;
