import { ContaReceber } from '../Entity/ContaReceber';
import { FindManyReq } from '../Entity/TypeormTypes';
import {
  DadosContaReceberBaixar,
  DadosListagemContasReceberCliente,
  ParametrosGerarQRCode
} from '../types/ContaReceberTypes';
import { ResCobrancaPixImediata } from '../types/SicoobTypes';
import api from './apiEdCliente';

const PREFIX = 'api';
const ROUTE = 'edcliente/contaReceber';

class ApiContaReceber {
  async getMany(query: FindManyReq<ContaReceber>) {
    try {
      const res = await api.post<DadosListagemContasReceberCliente[]>(`/${PREFIX}/${ROUTE}/get`, {
        query
      });
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async count(query: FindManyReq<ContaReceber>) {
    try {
      const res = await api.post<number>(`/${PREFIX}/${ROUTE}/count`, { query });

      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async gerarQrCodePix(parametros: ParametrosGerarQRCode) {
    try {
      const res = await api.post<ResCobrancaPixImediata>(`/${PREFIX}/${ROUTE}/gerarQrCodePix`, {
        parametros
      });
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async consultarCobrancaImediata(txid: string) {
    try {
      const res = await api.get<ResCobrancaPixImediata>(
        `/${PREFIX}/${ROUTE}/consultarCobrancaPix?txid=${txid}`
      );
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async baixarContaReceber(contaReceber: DadosContaReceberBaixar) {
    try {
      const res = await api.post<{ message: string }>(
        `/${PREFIX}/${ROUTE}/baixarContaReceber`,
        { contaReceber },
        { timeout: 3000000 }
      );

      const data = res.data;
      return data.message;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async getTotalDebito(codigoCliente: string) {
    try {
      const res = await api.get<number>(
        `/${PREFIX}/${ROUTE}/getTotalDebito?codigoCliente=${codigoCliente}`
      );
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }
  async getTotalDebitosEdcliente() {
    try {
      const res = await api.get<{
        totalDebitosVencidos: number;
        totalDebitosAVencer: number;
      }>(`/${PREFIX}/${ROUTE}/getTotalDebitosEdcliente`);
      const data = res.data;
      console.log();
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }
}

const apiContaReceber = new ApiContaReceber();
export default apiContaReceber;
