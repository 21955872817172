import FinanceiroPage from '../../Pages/Financeiro/FinanceiroPage';
import InicioPage from '../../Pages/Inicio/InicioPage';
import HomeIcon from '@mui/icons-material/Home';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import { ItemMenuType } from './ItemMenuType';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import PedidosPage from '../../Pages/Pedido/PedidosClientePage';
import { Hidden } from '@mui/material';
import Container from '../generic/Container';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import PerfilPage from '../../Pages/Perfil/PerfilPage';
import NovoPedidoPage from '../../Pages/Pedido/NovoPedidoCliente';

const ItemMenuInicio: ItemMenuType = {
  key: 'InicioPage',
  componente: (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        whiteSpace: 'normal'
      }}
    >
      <InicioPage />
    </div>
  ),
  icon: <HomeIcon />,
  label: 'Início',
  path: '/',
  show: true
};

export const ItemMenuFinanceiro: ItemMenuType = {
  key: 'FinanceiroPage',
  componente: (
    <>
      <Hidden mdUp>
        <FinanceiroPage />
      </Hidden>

      <Hidden mdDown>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            whiteSpace: 'normal'
          }}
        >
          <Container page>
            <FinanceiroPage />
          </Container>
        </div>
      </Hidden>
    </>
  ),
  icon: <LocalAtmIcon />,
  label: 'Financeiro',
  path: 'financeiro',
  show: true
};

export const ItemMenuPedido: ItemMenuType = {
  key: 'PedidoPage',
  componente: (
    <>
      <Hidden mdUp>
        <PedidosPage />
      </Hidden>

      <Hidden mdDown>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            whiteSpace: 'normal'
          }}
        >
          <Container page>
            <PedidosPage />
          </Container>
        </div>
      </Hidden>
    </>
  ),
  icon: <ShoppingCartOutlinedIcon />,
  label: 'Pedidos',
  path: 'pedido',
  show: true
};

export const ItemMenuMeuPerfil: ItemMenuType = {
  key: 'Perfil',

  componente: (
    <>
      <Hidden mdUp>
        <PerfilPage />
      </Hidden>

      <Hidden mdDown>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            whiteSpace: 'normal',
            margin: '0% 5%'
          }}
        >
          <PerfilPage />
        </div>
      </Hidden>
    </>
  ),
  icon: <AccountBoxIcon />,
  label: 'Meu perfil',
  path: 'perfil',
  show: true
};
export const NovoPedido: ItemMenuType = {
  key: 'NovoPedido',

  componente: (
    <>
      <Hidden mdUp>
        <NovoPedidoPage />
      </Hidden>

      <Hidden mdDown>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            whiteSpace: 'normal',
            margin: '0% 5%'
          }}
        >
          <NovoPedidoPage />
        </div>
      </Hidden>
    </>
  ),
  icon: <AccountBoxIcon />,
  label: 'NovoPedido',
  path: 'novoPedido',
  show: false
};

export const itensMenu: ItemMenuType[] = [
  ItemMenuInicio,
  ItemMenuFinanceiro,
  ItemMenuPedido,
  ItemMenuMeuPerfil,
  NovoPedido
];
