import { useEffect, useState } from 'react';
import apiEmpresa from '../../api/apiEmpresa';

const LogoEmpresa: React.FC = () => {
  const [urlImagem, setUrlImagem] = useState<string | undefined>('');

  useEffect(() => {
    const getLogoEmpresaDEPRECATED = async () => {
      try {
        const res = await apiEmpresa.getLogoEmpresa();
        setUrlImagem(res);
      } catch (erro: any) {
        console.error(erro);
      }
    };

    const getLogoEmpresa = async () => {
      try {
        const res = await apiEmpresa.getDadosPublicosEmpresa();
        setUrlImagem(res.urlImagem);
      } catch (erro: any) {
        getLogoEmpresaDEPRECATED();
        console.error(erro);
      }
    };

    getLogoEmpresa();
  }, []);

  return (
    <>
      {!!urlImagem && (
        <div
          style={{
            textAlign: 'center',
            margin: '10px 0 10px 0',
            flexDirection: 'column'
          }}
        >
          <img src={urlImagem} width={150} alt="logo empresa" />
        </div>
      )}
    </>
  );
};

export default LogoEmpresa;
