import { formatarDinheiro } from '../../helper';
import { Grid, Paper, Typography } from '@mui/material';
import { DataGrid, GridColDef, ptBR } from '@mui/x-data-grid';
import ModalGeneric from '../../Components/generic/ModalGeneric';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import LinearProgress from '@mui/joy/LinearProgress';
import PedidoLV from '../../Entity/PedidoLV';
import PedidoProdutoLV from '../../Entity/PedidoProdutoLV';
import apiPedidoProdutoLV from '../../api/apiPedidoProdutoLV';
import Info from '../../Components/generic/Info';

type Props = {
  pedidoSelecionado: PedidoLV;
  onClose: () => void;
  open: boolean;
};

const ModalDetalhesPedidoEmAnalise: React.FC<Props> = (props) => {
  const { pedidoSelecionado, onClose, open } = props;

  const [loading, setLoading] = useState(true);
  const [produtos, setProdutos] = useState<PedidoProdutoLV[]>([]);

  const columns: GridColDef[] = [
    {
      field: 'produto',
      headerName: 'Produto',
      width: 250,
      align: 'left',
      editable: false,
      renderCell: (row) => {
        const item = row.row as PedidoProdutoLV;
        return <span style={{ padding: '10px' }}>{item.produto.descricao}</span>;
      }
    },
    {
      field: 'quantidade',
      headerName: 'Qtd.',
      width: 50,
      align: 'left',
      editable: false,
      renderCell: (row) => {
        const item = row.row as PedidoProdutoLV;
        return <span style={{ padding: '10px' }}>{item.quantidade} </span>;
      }
    },
    {
      field: 'precoBruto',
      headerName: 'Pr. Bruto',
      width: 100,
      align: 'left',
      editable: false,
      renderCell: (row) => {
        const item = row.row as PedidoProdutoLV;
        return <span style={{ padding: '10px' }}>{formatarDinheiro(item.valor_unitario)}</span>;
      }
    },

    {
      field: 'precoLiquido',
      headerName: 'Subtotal',
      width: 100,
      align: 'left',
      editable: false,
      renderCell: (row) => {
        const item = row.row as PedidoProdutoLV;
        return <span style={{ padding: '10px' }}> {formatarDinheiro(item.total)}</span>;
      }
    }
  ];

  const getProdutos = async () => {
    setLoading(true);
    try {
      const res = await apiPedidoProdutoLV.getMany({
        where: { id_pedido: pedidoSelecionado.numeroPedidoLV },
        relations: ['produto']
      });
      setProdutos(res);
    } catch (err: any) {
      toast.error('Ocorreu um erro ao buscar pedido.');
      console.log(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    getProdutos();
     
  }, []);

  return (
    <ModalGeneric
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Typography component={'div'} variant="h4">
            Pedido #{pedidoSelecionado.numeroPedidoLV}
          </Typography>
        </Grid>

        {loading && (
          <Grid item xs={12}>
            <Typography component={'div'} variant="h6" style={{ textAlign: 'center' }}>
              Carregando detalhes . . .
            </Typography>
            <LinearProgress color="primary" size="lg" value={50} variant="soft" />
          </Grid>
        )}

        {!loading && (
          <>
            <Grid item xs={6}>
              <Info label={'Valor'} fontSize={16}>
                R$ {formatarDinheiro(pedidoSelecionado.valorPedido)}
              </Info>
            </Grid>
          </>
        )}

        {produtos.length > 0 && (
          <>
            <Grid item xs={12}>
              <Typography component={'div'} variant="h6">
                Itens do Pedido
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{ display: { xs: 'flex', md: 'none' } }}
              style={{ flexDirection: 'column' }}
            >
              {produtos.map((item) => (
                <Paper style={{ margin: '8px 0px', padding: '12px' }} key={item.id}>
                  <Grid container height={'100%'}>
                    <Grid item xs={12} textAlign={'left'}>
                      <Typography fontWeight={'bold'} variant="h6">
                        {item.produto.descricao}
                      </Typography>
                    </Grid>

                    <Grid item xs={4} marginTop={'10px'}>
                      Qnt: <Typography>{item.quantidade}</Typography>
                    </Grid>

                    <Grid item xs={4} marginTop={'10px'}>
                      R$ <Typography>{formatarDinheiro(item.valor_unitario)}</Typography>
                    </Grid>

                    <Grid item xs={4} marginTop={'10px'}>
                      <Typography>
                        <div>TOTAL</div>
                        {formatarDinheiro(item.total)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              ))}
            </Grid>

            <Grid item xs={12}>
              <DataGrid
                sx={{ display: { xs: 'none', md: 'flex' } }}
                rows={produtos}
                columns={columns}
                pageSizeOptions={[5]}
                rowCount={produtos.length}
                getRowHeight={() => 'auto'}
                getRowId={(row) => row.codigo_produto}
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
              />
            </Grid>
          </>
        )}
      </Grid>
    </ModalGeneric>
  );
};

export default ModalDetalhesPedidoEmAnalise;
