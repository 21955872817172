import { createReducer, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { setCfgGeralAction } from './cfgGeralActions';
import { CfgGeralState } from './cfgGeralTypes';
import { URLS_CLIENTES } from '../../constants';

export const initialState: CfgGeralState = {
  urlRemota: 'http://localhost:3003'
};

function getInitialState() {
  const appName = process.env.REACT_APP_COMPANY ?? '';

  const currentURL = window.location.href;

  let cfgGeral: CfgGeralState = {
    urlRemota: ''
  };

  if (currentURL.includes('.edcliente.com.br')) {
    const urlPartida = currentURL.split('.edcliente.com.br')[0];
    const nomeEmpresa = urlPartida.split('https://')[1];
    if (URLS_CLIENTES[nomeEmpresa]) {
      cfgGeral = URLS_CLIENTES[nomeEmpresa];
    }
  } else if (appName && URLS_CLIENTES[appName]) {
    cfgGeral = URLS_CLIENTES[appName];
  } else if (currentURL.includes('localhost')) {
    cfgGeral.urlRemota = 'http://localhost:3003';
  } else {
    const urlPartida = currentURL.split(':');
    const http = urlPartida[0];
    const ip = urlPartida[1];
    const porta = '3003';
    cfgGeral.urlRemota = `${http}:${ip}:${porta}`;
  }

  return cfgGeral;
}

const cfgGeralReducer = createReducer(
  getInitialState(),
  (builder: ActionReducerMapBuilder<CfgGeralState>) => {
    builder.addCase(setCfgGeralAction.toString(), (state, action) => {
      if (setCfgGeralAction.match(action)) {
        return { ...state, ...action.payload };
      }
      return { ...state };
    });
  }
);

export default cfgGeralReducer;
