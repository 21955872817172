import { useEffect, useState, useRef } from 'react';
import { Card, Grid, IconButton, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CardTotalizadores from '../../Components/generic/CardTotalizadores';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import UpcomingIcon from '@mui/icons-material/Upcoming';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import { formatarDinheiro, isMobile, mostrarToast } from '../../helper';
import { useSessao } from '../../redux/sessao/sessaoHooks';
import apiContaReceber from '../../api/apiContaReceber';
import apiPedido from '../../api/apiPedido';
import { EdNot } from '../../Entity/TypeormTypes';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const CardsFinanceiro: React.FC = () => {
  const navigate = useNavigate();
  const [sessao] = useSessao();
  const [totalDebitoVencido, setTotalDebitoVencido] = useState(0);
  const [totalDebitoAVencer, setTotalDebitoAVencer] = useState(0);
  const [compras, setCompras] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const totalCards = 3;

  const codigoCliente = sessao?.clienteUsuario?.codigoCliente;
  const codigoEmpresa = sessao?.empresa?.codigoEmpresa;

  const carouselRef = useRef<HTMLDivElement>(null);

  const getDebitos = async () => {
    try {
      if (!codigoCliente) return;
      setLoading(true);

      const totais = await apiContaReceber.getTotalDebitosEdcliente();
      const totalDebitosAVencer = totais.totalDebitosAVencer;
      const totalDebitosVencidos = totais.totalDebitosVencidos;

      setTotalDebitoVencido(totalDebitosVencidos);
      setTotalDebitoAVencer(totalDebitosAVencer);
    } catch (error) {
      console.error(error);
      mostrarToast(`Falha ao buscar débitos: ${error}`, 'error');
      return;
    } finally {
      setLoading(false);
    }
  };

  const getCompras = async () => {
    try {
      if (!codigoCliente) return;
      setLoading(true);

      const qntCompras = await apiPedido.count({
        where: { codigoCliente: codigoCliente, cancelado: EdNot('S'), codigoEmpresa: codigoEmpresa }
      });

      if (qntCompras) {
        setCompras(qntCompras);
      }
    } catch (error) {
      console.error(error);
      mostrarToast(`Falha ao buscar débitos: ${error}`, 'error');
      return;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDebitos();
    getCompras();
     
  }, []);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % totalCards);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + totalCards) % totalCards);
  };

  const handleDragStart = (
    e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>
  ) => {
    e.preventDefault();
    const start =
      e.type === 'mousedown'
        ? (e as React.MouseEvent).clientX
        : (e as React.TouchEvent).touches[0].clientX;

    const onDragMove = (e: MouseEvent | TouchEvent) => {
      const current = e instanceof MouseEvent ? e.clientX : (e as TouchEvent).touches[0].clientX;
      const diff = start - current;
      if (Math.abs(diff) > 50) {
        if (diff > 0) handleNext();
        else handlePrev();
        cleanup();
      }
    };

    const cleanup = () => {
      document.removeEventListener('mousemove', onDragMove);
      document.removeEventListener('mouseup', onDragEnd);
      document.removeEventListener('touchmove', onDragMove);
      document.removeEventListener('touchend', onDragEnd);
    };

    const onDragEnd = () => cleanup();

    document.addEventListener('mousemove', onDragMove);
    document.addEventListener('mouseup', onDragEnd);
    document.addEventListener('touchmove', onDragMove);
    document.addEventListener('touchend', onDragEnd);
  };

  const carouselStyle: React.CSSProperties = {
    display: 'flex',
    overflow: 'hidden',
    width: '100%',
    touchAction: 'none',
    position: 'relative'
  };

  const cardStyle: React.CSSProperties = {
    flex: 'none',
    width: '100%',
    transition: 'transform 0.5s ease-in-out',
    transform: `translateX(-${currentIndex * 100}%)`
  };

  return (
    <Grid item container>
      {isMobile() ? (
        <>
          <Grid
            ref={carouselRef}
            onMouseDown={handleDragStart as any}
            onTouchStart={handleDragStart}
            style={carouselStyle}
          >
            <Grid style={cardStyle}>
              <CardTotalizadores
                titulo={'Total débitos vencidos'}
                value={
                  totalDebitoVencido !== 0
                    ? `RS ${formatarDinheiro(totalDebitoVencido)}`
                    : 'RS 0,00'
                }
                icon={<AccessTimeIcon sx={{ fontSize: '40px' }} />}
                backgroundColor={'#d32f2f'}
                color={'whitesmoke'}
                telaDeNavegacao="financeiro"
                loading={loading}
                onClick={() => navigate('/financeiro')}
              />
            </Grid>
            <Grid style={cardStyle}>
              <CardTotalizadores
                titulo={'Total débitos à vencer'}
                value={
                  totalDebitoAVencer ? `RS ${formatarDinheiro(totalDebitoAVencer)}` : 'RS 0,00'
                }
                icon={<UpcomingIcon sx={{ fontSize: '40px' }} />}
                backgroundColor={'#FF8C00'}
                color={'whitesmoke'}
                telaDeNavegacao="financeiro"
                loading={loading}
                onClick={() => navigate('/financeiro')}
              />
            </Grid>
            <Grid style={cardStyle}>
              <CardTotalizadores
                titulo={'Minhas compras'}
                value={compras.toString() || '0'}
                icon={<LocalMallIcon sx={{ fontSize: '40px' }} />}
                backgroundColor={'#006400'}
                color={'whitesmoke'}
                telaDeNavegacao="pedidos"
                loading={loading}
                onClick={() => navigate('/pedido')}
              />
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              justifyContent: 'space-between',
              width: '100%'
            }}
          >
            <IconButton onClick={handlePrev}>
              <KeyboardArrowLeftIcon style={{ color: '#2196f3' }} />
            </IconButton>

            <IconButton onClick={handleNext}>
              <KeyboardArrowRightIcon style={{ color: '#2196f3' }} />
            </IconButton>
          </Grid>
        </>
      ) : (
        <>
          <Grid
            item
            xs={12}
            sx={{
              marginTop: { xs: '0', md: '30px' },
              padding: '10px',
              borderRadius: '12px'
            }}
          >
            <Card
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: '50px',
                padding: '10px'
              }}
            >
              <Typography fontSize={20} style={{ textAlign: 'center' }}>
                Meus débitos
              </Typography>
            </Card>
          </Grid>
          <CardTotalizadores
            titulo={'Total débitos vencidos'}
            value={
              totalDebitoVencido !== 0 ? `RS ${formatarDinheiro(totalDebitoVencido)}` : 'RS 0,00'
            }
            icon={<AccessTimeIcon sx={{ fontSize: '40px' }} />}
            backgroundColor={'#d32f2f'}
            color={'whitesmoke'}
            telaDeNavegacao="financeiro"
            loading={loading}
            onClick={() => navigate('/financeiro')}
          />
          <CardTotalizadores
            titulo={'Total débitos à vencer'}
            value={totalDebitoAVencer ? `RS ${formatarDinheiro(totalDebitoAVencer)}` : 'RS 0,00'}
            icon={<UpcomingIcon sx={{ fontSize: '40px' }} />}
            backgroundColor={'#FF8C00'}
            color={'whitesmoke'}
            telaDeNavegacao="financeiro"
            loading={loading}
            onClick={() => navigate('/financeiro')}
          />
          <CardTotalizadores
            titulo={'Minhas compras'}
            value={compras.toString() || '0'}
            icon={<LocalMallIcon sx={{ fontSize: '40px' }} />}
            backgroundColor={'#006400'}
            color={'whitesmoke'}
            telaDeNavegacao="pedidos"
            loading={loading}
            onClick={() => navigate('/pedido')}
          />
        </>
      )}
    </Grid>
  );
};

export default CardsFinanceiro;
