import ProdutoLV from '../Entity/ProdutoLV';
import { FindManyReq } from '../Entity/TypeormTypes';
import { ProdutosDisponiveisEdCliente } from '../types/NovoPedidoTypes';
import api from './apiEdCliente';

const PREFIX = 'api';
const ROUTE = 'edcliente/produtoLV';

class ApiProdutoLV {
  async getMany(query: FindManyReq<ProdutoLV>, pesquisa: string, currentURL: string) {
    try {
      const res = await api.post<{
        totalResultados: number;
        produtos: ProdutosDisponiveisEdCliente[];
      }>(`/${PREFIX}/${ROUTE}/get`, {
        query,
        pesquisa,
        currentURL
      });
      const data = res.data;

      return data;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  }

  async count(query: FindManyReq<ProdutoLV>) {
    try {
      const res = await api.post<number>(`/${PREFIX}/${ROUTE}/count`, { query });
      const data = res.data;
      return data;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  }
}

const apiProdutoLV = new ApiProdutoLV();
export default apiProdutoLV;
