import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import sessaoReducer from './sessao/sessaoReducer';
import cfgGeralReducer from './cfgGeral/cfgGeralReducer';
import StateReducer from './state/stateReducer';

export const rootReducer = combineReducers({
  sessao: sessaoReducer,
  cfgGeral: cfgGeralReducer,
  appState: StateReducer
});

const persistConfig = {
  key: '@redux',
  storage,
  blacklist: ['snack', 'loadingGeral', 'dialog', 'cfgGeral']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export type RootAppState = ReturnType<typeof persistedReducer>;

const store = configureStore({
  reducer: persistedReducer
});
export const persistor = persistStore(store);

export default store;
