import { createReducer, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { SessaoState } from '../reduxTypes';
import { loginAction, logoutAction } from './sessaoActions';

export const initialState: SessaoState = {
  token: null,
  cliente: null,
  empresa: null,
  clienteUsuario: null
};

const sessaoReducer = createReducer(
  initialState,
  (builder: ActionReducerMapBuilder<SessaoState>) => {
    builder.addCase(loginAction.toString(), (state, action) => {
      if (loginAction.match(action)) {
        return { ...state, ...action.payload };
      }
    });
    builder.addCase(logoutAction, (state, action) => {
      if (logoutAction.match(action)) {
        const newState = { ...state, cliente: null, empresa: null };
        return newState;
      }
    });
  }
);

export default sessaoReducer;
