import { CardMedia, Divider, Grid, Tooltip, Typography } from '@mui/material';
import ModalGeneric from '../../Components/generic/ModalGeneric';
import { ProdutosDisponiveisEdCliente } from '../../types/NovoPedidoTypes';
import SEM_FOTO from '../../../src/assets/sem-foto.jpg';
import ButtonGeneric from '../../Components/generic/ButtonGeneric';
import QuantidadeField from '../../Components/generic/QuantidadeField';
import { useSessao } from '../../redux/sessao/sessaoHooks';
import { useEffect, useState } from 'react';
import { mostrarToast } from '../../helper';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

type Props = {
  adicionarProdutoAoCarrinho: (produto: ProdutosDisponiveisEdCliente) => void;
  produto: ProdutosDisponiveisEdCliente;
  onClose: () => void;
  open: boolean;
};

const ModalAdicionarProduto: React.FC<Props> = (props) => {
  const { produto, onClose, open, adicionarProdutoAoCarrinho } = props;

  const [produtoSelecionado, setProdutoSelecionado] = useState<ProdutosDisponiveisEdCliente>();
  const [sessao] = useSessao();
  const exibirQtdEstoqueDisponivel = sessao.empresa?.exibirQuantidadeEstoqueDisponivel;
  const verificaEstoque = sessao.empresa?.verificaEstoque;

  useEffect(() => {
    if (produto) {
      setProdutoSelecionado(produto);
    }
  }, [produto]);

  const alterarQuantidadePedida = (novoValor: number, quantidadeMax: number) => {
    if (novoValor < 0) {
      mostrarToast('Estoque insuficiente ou quantidade inválida!', 'warning');
      return;
    }

    if (verificaEstoque && novoValor > quantidadeMax) {
      mostrarToast('Estoque insuficiente ou quantidade inválida!', 'warning');
      return;
    }

    if (produtoSelecionado) {
      setProdutoSelecionado({ ...produtoSelecionado, quantidadePedida: novoValor });
    }
  };

  return (
    <ModalGeneric
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <Grid container spacing={3}>
        <Grid item container xs={6}>
          <Grid item xs={12}>
            <CardMedia
              component="img"
              alt={produto.descricao}
              style={{ height: '140px', width: '100%', objectFit: 'contain' }}
              src={produto.urlImagem === 'SEM_FOTO' ? SEM_FOTO : produto.urlImagem}
              title={produto.descricao}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2} xs={6}>
          <Grid item xs={12}>
            <Tooltip title={produto?.descricao}>
              <Typography
                variant="body2"
                style={{
                  fontWeight: 'bolder',
                  fontSize: '15px',
                  marginBottom: '5px',
                  display: '-webkit-box',
                  overflow: 'hidden'
                  // textOverflow: 'ellipsis',
                  // WebkitBoxOrient: 'vertical',
                  // WebkitLineClamp: 2
                }}
              >
                {produto?.descricao}
              </Typography>
            </Tooltip>
            <Typography variant="body2">Cód: {produto.codigo}</Typography>
            {produto.referencia && (
              <Typography variant="body2">Ref: {produto.referencia}</Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              component="p"
              style={{ fontWeight: 'bold', fontSize: '20px' }}
            >
              R$ {produto.preco}
            </Typography>
            {exibirQtdEstoqueDisponivel && (
              <Typography variant="body1" component="p" style={{ fontSize: '12px' }}>
                Quantidade disponível: {produto.quantidadeEstoque}
              </Typography>
            )}
          </Grid>
          <Grid item xs={8}>
            <QuantidadeField
              valor={produtoSelecionado?.quantidadePedida ?? 0}
              setValor={(novoValor) =>
                alterarQuantidadePedida(novoValor, produto.quantidadeEstoque)
              }
              autoFocus
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Divider light />
        </Grid>

        <Grid item xs={12} textAlign={'center'}>
          <ButtonGeneric
            label={'Fazer pedido'}
            onClick={function (): void {}}
            style={{ width: '60%', color: 'white', backgroundColor: '#2196f3', fontWeight: 'bold' }}
          />
        </Grid>

        <Grid item xs={12} textAlign={'center'} style={{ marginTop: '-15px' }}>
          <ButtonGeneric
            label={'Adicionar ao carrinho'}
            startIcon={<ShoppingCartIcon />}
            onClick={async () => {
              if (produtoSelecionado && produtoSelecionado.quantidadePedida > 0) {
                console.log('produtoSelecionado', produtoSelecionado);
                await adicionarProdutoAoCarrinho(produtoSelecionado);
              } else {
                mostrarToast('Quantidade inválida', 'warning');
              }
            }}
            style={{
              width: '60%',
              color: '#2196f3',
              backgroundColor: '#c3e2fa',
              fontWeight: 'bold'
            }}
          />
        </Grid>
      </Grid>
    </ModalGeneric>
  );
};

export default ModalAdicionarProduto;
