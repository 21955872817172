import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { RootAppState } from '../../redux/store';
import { ItemMenuType } from './ItemMenuType';
import SidebarItem from './SiderBarItem';

type Props = {
  item: ItemMenuType;
};

const SidebarItemCollapse = ({ item }: Props) => {
  const [open, setOpen] = useState(false);

  const { state } = useSelector((state: RootAppState) => state.appState);

  console.log('yyyy', state);
  useEffect(() => {
    if (state.includes(item.key)) {
      setOpen(true);
    }
    console.log('yyyy', item);
  }, [state, item]);

  return (
    item && (
      <>
        <ListItemButton onClick={() => setOpen(!open)}>
          <ListItemIcon>{item.label && item.icon}</ListItemIcon>
          <ListItemText disableTypography primary={<Typography>{item.label}</Typography>} />
          {open ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
        </ListItemButton>
        <Collapse in={open} timeout="auto">
          <List>
            {item.child?.map((route, index) => {
              if (route.label) {
                return route.child ? (
                  <SidebarItemCollapse item={route} key={index} />
                ) : (
                  <SidebarItem item={route} key={index} />
                );
              }
              return null;
            })}
          </List>
        </Collapse>
      </>
    )
  );
};

export default SidebarItemCollapse;
