import { useState } from 'react';
import { useSessao } from '../../redux/sessao/sessaoHooks';
import { toast } from 'react-toastify';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Button, IconButton, InputAdornment, Paper, TextField, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import EdParticles from '../../Components/generic/EdParticles';
import FooterTelaLogin from '../../Components/generic/FooterTelaLogin';
import ModalCadastroUsuario from './ModalCadastroUsuario';

import Loader from '../../Components/generic/Loader';
import LogoEmpresa from '../../Components/generic/LogoEmpresa';

const LoginPage: React.FC = () => {
  const [, login] = useSessao();
  const [loading, setLoading] = useState(false);
  const [usuario, setUsuario] = useState('');
  const [senha, setSenha] = useState('');
  const [verSenha, setVerSenha] = useState(false);
  const [openModalCadastro, setOpenModalCadastro] = useState(false);

  const classes = useStyles();

  const onLogin = async () => {
    try {
      setLoading(true);
      await login(usuario, senha);
      setLoading(false);
    } catch (err: any) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      toast.error(err.response.data);
      console.log('Erro', err);
    }
  };

  return (
    <div>
      <EdParticles />

      <div className={classes.content}>
        <Paper className={classes.form}>
          <LogoEmpresa />

          <TextField
            label={'CPF/CNPJ'}
            variant={'outlined'}
            value={usuario}
            onChange={(event) => {
              setUsuario(event.target.value);
            }}
            fullWidth
            disabled={loading}
            sx={{ marginBottom: '15px' }}
          />

          <TextField
            label="Senha"
            value={senha}
            onChange={(event) => setSenha(event.target.value)}
            type={verSenha ? 'text' : 'password'}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                onLogin();
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setVerSenha(!verSenha)}
                  >
                    {verSenha ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            fullWidth
            disabled={loading}
            sx={{ marginBottom: '15px' }}
          />

          <div className={classes.buttonContainer}>
            {loading && (
              <div className={classes.loaderContainer}>
                <Loader size={36} />
              </div>
            )}

            {!loading && (
              <Button onClick={onLogin} variant="contained" sx={{ marginBottom: '15px' }}>
                <Typography>Entrar</Typography>
              </Button>
            )}

            <div
              className={classes.newAccountButton}
              onClick={() => {
                setOpenModalCadastro(true);
              }}
            >
              <Typography fontSize={14} color={(theme) => theme.palette.primary.main}>
                Novo por aqui?
                <br />
                <span>Clique aqui para se cadastrar!</span>
              </Typography>
            </div>
          </div>

          <FooterTelaLogin />
        </Paper>
      </div>

      {openModalCadastro && (
        <ModalCadastroUsuario
          open={openModalCadastro}
          onClose={() => setOpenModalCadastro(false)}
        />
      )}
    </div>
  );
};

export default LoginPage;

const useStyles = makeStyles((theme) =>
  createStyles({
    content: {
      display: 'flex',
      color: '#fff',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      position: 'absolute',
      minHeight: '100vh',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%'
    },
    form: {
      background: '#fff',
      minWidth: 'min(350px, 95vw)',
      padding: '20px',
      display: 'flex',
      flexDirection: 'column'
    },
    buttonContainer: { display: 'flex', flexDirection: 'column', alignItems: 'center' },
    loaderContainer: { display: 'flex', justifyContent: 'center', marginBottom: '15px' },
    newAccountButton: {
      marginBottom: '20px',
      cursor: 'pointer',
      width: '100%',
      textAlign: 'center'
    }
  })
);
