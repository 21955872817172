import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './app.css';

import { routes } from './Components/Menu';
import MainLayout from './Components/BaseLayout/MainLayout';
import CssBaseline from '@material-ui/core/CssBaseline';
import PoliticasPrivacidadePage from './Pages/PoliticaPrivacidadePage/PoliticaPrivacidadePage';

function App() {
  return (
    <BrowserRouter>
      <ToastContainer />
      <CssBaseline />
      <Routes>
        <Route path="/politica-privacidade" element={<PoliticasPrivacidadePage />} />
        <Route path="/" element={<MainLayout />}>
          {routes}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
