import { CobrancaPix } from '../Entity/CobrancaPix';
import api from './apiEdCliente';

const PREFIX = 'api';
const ROUTE = '/edcliente/cobrancaPi';

class ApiCobrancaPix {
  async update(params: Partial<CobrancaPix>, update: Partial<CobrancaPix>) {
    try {
      const res = await api.put<CobrancaPix>(`/${PREFIX}/${ROUTE}/update`, { update }, { params });
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }
}

const apiCobrancaPix = new ApiCobrancaPix();
export default apiCobrancaPix;
