import { useEffect, useState } from 'react';
import { Pedido } from '../../Entity/Pedido';
import { EdBetween, EdGreaterThan, EdLessThan, FindManyReq } from '../../Entity/TypeormTypes';
import dayjs, { Dayjs } from 'dayjs';
import { formatarDinheiro, useUpdateEffect } from '../../helper';
import apiPedido from '../../api/apiPedido';
import { toast } from 'react-toastify';
import Container from '../../Components/generic/Container';
import { Grid, IconButton, LinearProgress, Paper, Typography } from '@mui/material';
import { DataGrid, GridColDef, ptBR } from '@mui/x-data-grid';
import { useClienteLogado } from '../../redux/sessao/sessaoHooks';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import ModalDetalhesPedido from './ModalDetalhesPedido';

type Props = {
  dataInicio: Dayjs;
  dataFim: Dayjs;
};

const ListaPedidosClientePage: React.FC<Props> = (props) => {
  const { dataInicio, dataFim } = props;

  const [loading, setLoading] = useState(true);
  const [pedidos, setPedidos] = useState<Pedido[]>([]);
  const [query, setQuery] = useState<FindManyReq<Pedido> | null>(null);
  const [pagina, setPagina] = useState(0);
  const linhasPorPagina = 10;
  const [totalLinhas, setTotalLinhas] = useState(0);
  const clienteLogado = useClienteLogado();
  const [pedidoSelecionado, setPedidoSelecionado] = useState<Pedido | null>(null);

  useEffect(() => {
    buscar();
     
  }, [dataInicio, dataFim]);

  useUpdateEffect(() => {
    getPedidos();
  }, [query, pagina]);

  const getPedidos = async () => {
    if (!query) {
      return;
    }

    try {
      setLoading(true);
      query.skip = pagina * linhasPorPagina;
      query.take = linhasPorPagina;
      const pedidos = await apiPedido.getMany(query);
      setPedidos(pedidos);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.error(error.DUPR_STATUS_EM_ABERTO);
    }
  };

  const buscar = async () => {
    const query: FindManyReq<Pedido> = {};
    query.where = {};
    query.order = { numeroPedido: 'DESC' };

    query.where.codigoCliente = clienteLogado?.codigo;

    if (dataInicio && dataFim) {
      query.where.dataEmissao = EdBetween(
        `${dataInicio.format('YYYYMMDD')}`,
        `${dataFim.format('YYYYMMDD')}`
      );
    } else if (dataInicio) {
      query.where.dataEmissao = EdGreaterThan(`${dataInicio.format('YYYYMMDD')}`);
    } else if (dataFim) {
      query.where.dataEmissao = EdLessThan(`${dataFim.format('YYYYMMDD')}`);
    }

    try {
      setLoading(true);
      const totalLinhas = await apiPedido.count(query);
      setQuery(query);
      setTotalLinhas(totalLinhas);
      setPagina(0);
    } catch (error: any) {
      setLoading(false);
      toast.error(error.data);
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'numeroPedido',
      headerName: 'Numero',
      width: 100,
      align: 'left',
      editable: false,
      renderCell: (row) => {
        const pedido = row.row as Pedido;
        return pedido.numeroPedido;
      }
    },
    {
      field: 'dataEmissao',
      headerName: 'Data',
      width: 100,
      align: 'left',
      editable: false,
      renderCell: (row) => {
        const pedido = row.row as Pedido;
        return dayjs(pedido.dataEmissao).format('DD/MM/YYYY');
      }
    },
    {
      field: 'Valor',
      headerName: 'Valor R$',
      width: 100,
      align: 'left',
      editable: false,
      renderCell: (row) => {
        const pedido = row.row as Pedido;
        return formatarDinheiro(pedido.totalBruto - pedido.descontoGeral);
      }
    },
    {
      field: 'Ações',
      headerName: 'Ações',
      width: 100,
      align: 'left',
      editable: false,
      renderCell: (row) => {
        return (
          <>
            <IconButton onClick={() => {}}>
              <RemoveRedEyeOutlinedIcon />
            </IconButton>
          </>
        );
      }
    }
  ];

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sx={{ display: { xs: 'flex', md: 'none' } }}
          style={{ flexDirection: 'column' }}
        >
          {loading ? (
            <Grid item xs={12}>
              <Typography fontWeight={'bold'} variant="h6">
                Buscando informações . . .
              </Typography>
              <LinearProgress />
            </Grid>
          ) : (
            <>
              {pedidos.length === 0 && <>Nenhum pedido encontrado no período.</>}
              {pedidos.map((pedido) => (
                <Paper style={{ margin: '8px 0px', padding: '12px' }} key={pedido.numeroPedido}>
                  <Grid container height={'100%'}>
                    <Grid item xs={12} textAlign={'left'}>
                      <Typography fontWeight={'bold'} variant="h6">
                        Pedido N°: {pedido.numeroPedido}
                      </Typography>
                    </Grid>

                    <Grid item xs={6} marginTop={'10px'}>
                      <Typography>
                        <p>Data: {dayjs(pedido.dataEmissao).format('DD/MM/YYYY')}</p>
                      </Typography>
                    </Grid>

                    <Grid item xs={6} marginTop={'10px'}>
                      <Typography>
                        <div>TOTAL</div>
                        <div>R$ {formatarDinheiro(pedido.totalLiquido)}</div>
                      </Typography>
                    </Grid>

                    <Grid item xs={12} textAlign={'center'}>
                      <>
                        Visualizar
                        <IconButton onClick={() => setPedidoSelecionado(pedido)}>
                          <RemoveRedEyeOutlinedIcon />
                        </IconButton>
                      </>
                    </Grid>
                  </Grid>
                </Paper>
              ))}
            </>
          )}
        </Grid>
        <div style={{ height: 350, width: '100%' }}>
          <DataGrid
            sx={{ display: { xs: 'none', md: 'flex' } }}
            paginationModel={{ page: pagina, pageSize: 10 }}
            onPaginationModelChange={(model) => {
              setPagina(model.page);
            }}
            paginationMode="server"
            rowCount={totalLinhas}
            pageSizeOptions={[10]}
            loading={loading}
            rows={pedidos}
            columns={columns}
            getRowHeight={() => 'auto'}
            getRowId={(row) => row.numeroPedido}
            onRowClick={(obj) => setPedidoSelecionado(obj.row)}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          />
        </div>

        {pedidoSelecionado && (
          <ModalDetalhesPedido
            pedidoSelecionado={pedidoSelecionado}
            open={Boolean(pedidoSelecionado)}
            onClose={() => {
              setPedidoSelecionado(null);
            }}
          />
        )}
      </Grid>
    </Container>
  );
};

export default ListaPedidosClientePage;
