import { Cliente } from '../Entity/Cliente';
import { InformacoesCliente } from '../types/EdClienteTypes';
import api from './apiEdCliente';

const PREFIX = 'api';
const ROUTE = 'edcliente/cliente';

class ApiCliente {
  async update(params: Partial<Cliente>, update: Partial<Cliente>) {
    try {
      const res = await api.put<Cliente>(`/${PREFIX}/${ROUTE}/update`, { update }, { params });
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async buscarInformacoesCliente(documento: string) {
    const res = await api.post<InformacoesCliente>(
      `/${PREFIX}/${ROUTE}/buscarInformacoesCliente`,
      {
        documento
      },
      { timeout: 20000 }
    );
    const data = res.data;
    return data;
  }
}

const apiCliente = new ApiCliente();
export default apiCliente;
