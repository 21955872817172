import { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useClienteLogado, useSessao } from '../../redux/sessao/sessaoHooks';
import CardsFinanceiro from './CardsFinanceiro';
import { toast } from 'react-toastify';
import ModalCadastrarSenha from '../LoginPage/ModalCadastrarSenha';
import Container from '../../Components/generic/Container';
import CardsPedido from './CardsPedido';

const InicioPage: React.FC = () => {
  const clienteLogado = useClienteLogado();

  const [sessao] = useSessao();
  const [saudacao, setSaudacao] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [loading] = useState(false);

  const primeiroNome = clienteLogado?.nome.split(' ')[0] ?? '';
  const nomeCliente = `${primeiroNome.charAt(0).toUpperCase()}${primeiroNome
    .slice(1)
    .toLowerCase()}`;

  useEffect(() => {
    iniciar();
    if (sessao.clienteUsuario?.senha === '') {
      setOpenModal(true);
    }
     
  }, []);

  const clientePodeFazerPedido = sessao?.empresa?.clientePodeFazerPedido;

  const iniciar = async () => {
    try {
      if (!sessao.cliente) return;

      const getMomentOfDay = () => {
        const horaAtual = new Date().getHours();

        if (horaAtual >= 6 && horaAtual < 12) {
          return 'bom dia';
        } else if (horaAtual >= 12 && horaAtual < 18) {
          return 'boa tarde';
        } else {
          return 'boa noite';
        }
      };

      setSaudacao(getMomentOfDay());
    } catch (error: any) {
      toast.info(error);
      console.error('Erro: ', error);
    }
  };

  return (
    <Container page elevation={0} loading={loading}>
      <Grid container sx={{ marginTop: { xs: '-60px', md: '0' } }}>
        <Grid item xs={12} style={{ marginBottom: '5px' }}>
          <Typography fontSize={{ xs: 15, md: 25 }} style={{ textAlign: 'center' }}>
            Olá, {saudacao} {nomeCliente} !
          </Typography>
        </Grid>

        {clientePodeFazerPedido && <CardsPedido />}

        <CardsFinanceiro />

        <ModalCadastrarSenha
          open={openModal}
          onClose={() => {
            setOpenModal(false);
          }}
        />
      </Grid>
    </Container>
  );
};

export default InicioPage;
