import React, { ReactElement } from 'react';
import { useWindowSize } from '../../helper';
import Paper from '@mui/material/Paper/Paper';
import Loader from './Loader';
import theme from '../../theme';
import { makeStyles } from '@material-ui/core/styles';

type Props = {
  loading?: boolean;
  page?: boolean;
  style?: React.CSSProperties;
  reference?: ((instance: unknown) => void) | React.RefObject<unknown> | null | undefined;
  header?: ReactElement;
  children: ReactElement;
  elevation?: number;
};

const Container: React.FC<Props> = (props) => {
  const { children, loading, page, header, elevation } = props;
  const classes = useStyles();

  const size = useWindowSize();

  const newStyles = { maxWidth: '100%', width: '100%' };
  if (page && size.width > 1000) {
    newStyles.maxWidth = '1000px';
  }

  return (
    <div style={newStyles}>
      <Paper
        // ref={reference}
        // style={{ ...style, ...newStyles }}
        // className={classes.container}
        // {...other}
        elevation={elevation === 0 ? 0 : elevation ? elevation : 1}
      >
        {header}
        {loading ? (
          <div className={classes.content}>
            <Loader />
          </div>
        ) : (
          <div className={classes.padding}>{children}</div>
        )}
      </Paper>
    </div>
  );
};

const useStyles = makeStyles({
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '400px'
  },
  container: {
    width: '100%',
    overflow: 'hidden'
  },
  padding: {
    [theme.breakpoints.up('sm')]: {
      padding: 30
    },
    [theme.breakpoints.up('lg')]: {
      padding: 30,
      paddingLeft: 50,
      paddingRight: 50
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 30,
      paddingRight: 20,
      paddingLeft: 20,
      paddingBottom: 30
    }
  }
});

export default Container;
