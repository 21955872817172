import { createReducer, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { setCfgGeralAction } from '../cfgGeral/cfgGeralActions';
import { StateType } from './stateTypes';

export const initialState: StateType = {
  state: 'InicioPage'
};

const StateReducer = createReducer(initialState, (builder: ActionReducerMapBuilder<StateType>) => {
  builder.addCase(setCfgGeralAction.toString(), (state, action) => {
    if (setCfgGeralAction.match(action)) {
      return { ...state, ...action.payload };
    }
    return { ...state };
  });
});

export default StateReducer;
