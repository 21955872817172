import { useTheme } from '@mui/material';
import ClockLoader from 'react-spinners/ClockLoader';

type Props = {
  loading?: boolean;
  color?: string;
  size?: number;
};

const Loader: React.FC<Props> = (props) => {
  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;
  const { loading, color, size } = props;
  return <ClockLoader size={size ?? 70} color={color ?? primaryColor} loading={loading} />;
};

export default Loader;
