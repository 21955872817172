import { SessaoState } from '../redux/reduxTypes';
import { InformacoesCliente } from '../types/EdClienteTypes';
import api from './apiEdCliente';

const PREFIX = 'api';
const ROUTE = 'edcliente/auth';

class ApiAutenticacao {
  async enviarCodigoVerficacao(
    opcao: number,
    dadosCliente: InformacoesCliente,
    telefoneSelecionado: string
  ) {
    const res = await api.post<SessaoState>(`/${PREFIX}/${ROUTE}/enviarCodigoVerficacao`, {
      opcao,
      dadosCliente,
      telefoneSelecionado
    });
    const data = res.data;
    return data;
  }

  async confirmarCodigoVerificacao(codigoVerificacao: string, dadosCliente: InformacoesCliente) {
    const res = await api.post<SessaoState>(`/${PREFIX}/${ROUTE}/confirmarCodigoVerificacao`, {
      codigoVerificacao,
      dadosCliente
    });
    const data = res.data;
    return data;
  }

  async login(login: string, senha: string) {
    const res = await api.post<SessaoState>(
      `/${PREFIX}/${ROUTE}/`,
      {
        login,
        senha
      },
      { timeout: 20000 }
    );
    console.log('yyyy', res);
    const data = res.data;
    return data;
  }

  async cadastrarUsuarioCliente(documento: string, senha: string) {
    const res = await api.post<SessaoState>(
      `/${PREFIX}/${ROUTE}/cadastrarUsuarioCliente`,
      {
        documento,
        senha
      },
      { timeout: 20000 }
    );
    const data = res.data;
    return data;
  }

  async atualizarSenha(senha: string) {
    const res = await api.post<SessaoState>(`/${PREFIX}/${ROUTE}/atualizarSenha`, { senha });
    const data = res.data;
    return data;
  }

  async getStatusWhatsApp() {
    const res = await api.get<boolean>(`/${PREFIX}/${ROUTE}/getStatusWhatsApp`);
    const data = res.data;
    return data;
  }

  async getStatusEmail() {
    const res = await api.get<boolean>(`/${PREFIX}/${ROUTE}/getStatusEmail`);
    const data = res.data;
    return data;
  }
}

const apiAutenticacao = new ApiAutenticacao();
export default apiAutenticacao;
