import { Alert, Box, Button, Grid, LinearProgress, Typography } from '@mui/material';
import ModalGeneric from '../../Components/generic/ModalGeneric';
import { useEffect, useState } from 'react';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { InformacoesCliente } from '../../types/EdClienteTypes';
import TextFieldGeneric from '../../Components/generic/TextFieldGeneric';
import LogoEmpresa from '../../Components/generic/LogoEmpresa';
import { toast } from 'react-toastify';
import apiAutenticacao from '../../api/apiAutenticacao';
import { CODIGO_CONFIRMACAO_VIA_EMAIL, CODIGO_CONFIRMACAO_VIA_WHATSAPP } from '../../constants';
import CheckIcon from '@mui/icons-material/Check';
import { loginAction } from '../../redux/sessao/sessaoActions';

type Props = {
  open: boolean;
  onClose: () => void;
  dadosCliente: InformacoesCliente;
  apiEmailOk: boolean;
  apiZapOk: boolean;
};

const ModalConfirmarCadastro: React.FC<Props> = (props) => {
  const { open, onClose, dadosCliente, apiEmailOk, apiZapOk } = props;

  const [loading, setLoading] = useState(false);
  const [opcao, setOpcao] = useState(0);
  const [telefoneSelecionado, setTelefoneSelecionado] = useState('');

  const [pedirCodigo, setPedirCodigo] = useState(false);
  const [codigoConfirmacao, setCodigoConfirmacao] = useState('');
  const [semOpcaoDisponivel, setSemOpcaoDisponivel] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if ((!apiZapOk && !apiEmailOk) || (!apiZapOk && !dadosCliente.email)) {
      setSemOpcaoDisponivel(true);
    }
  }, [apiEmailOk, apiZapOk, dadosCliente.email]);

  function mascararEmail(email: string) {
    const partes = email.split('@');
    const usuarioMascarado =
      partes[0].slice(0, Math.floor(partes[0].length / 2)) +
      '*'.repeat(partes[0].length - Math.floor(partes[0].length / 2));
    return usuarioMascarado + '@' + partes[1];
  }

  function mascararTelefone(telefone: string) {
    return '*****' + telefone.substring(telefone.length, telefone.length - 4);
  }

  const enviarCodigoVerficacao = async () => {
    try {
      if (!opcao || opcao === 0) {
        toast.error('Escolha a opção para enviar o código de verificação.');
        return;
      }

      if (opcao === CODIGO_CONFIRMACAO_VIA_WHATSAPP && !telefoneSelecionado) {
        toast.error(
          'Por favor selecione o numero de Whats App que deseja receber o código de verificação'
        );
      }
      setLoading(true);
      await apiAutenticacao.enviarCodigoVerficacao(opcao, dadosCliente, telefoneSelecionado);
      toast.info('Código de confirmação enviado');
      setPedirCodigo(true);
      setLoading(false);
    } catch (erro: any) {
      toast.error('Erro');
      setLoading(false);
    }
  };

  const confirmarCodigoVerificacao = async () => {
    try {
      const res = await apiAutenticacao.confirmarCodigoVerificacao(codigoConfirmacao, dadosCliente);
      toast.success('Cadastro confirmado!');
      const action = loginAction(res);
      dispatch(action);
    } catch (erro: any) {
      toast.error('Erro ao verificar código de confirmação');
    }
  };

  return (
    <ModalGeneric
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <LogoEmpresa />

      {semOpcaoDisponivel || (!apiZapOk && !apiEmailOk) ? (
        <div style={{ margin: '15px', textAlign: 'center' }}>
          <Typography fontWeight={'bold'}>
            Os serviços de WhatsApp e E-mail, estão temporariamente oflines, tente novamente em
            alguns instantes...
          </Typography>
        </div>
      ) : (
        <div style={{ margin: '15px', textAlign: 'center' }}>
          <Grid item xs={12}>
            <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
              {dadosCliente.nome.split(' ')[0]}, encontramos o seu cadastro! Estamos ansiosos para
              continuar, mas antes, precisamos confirmar algumas informações. Por favor, escolha uma
              das opções abaixo para receber o código de verificação.
            </Alert>
          </Grid>

          <Grid
            container
            spacing={2}
            margin={'0px 0px 20px'}
            style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}
          >
            <Grid item xs={12}>
              <FormControl>
                <FormLabel id="radio-group"></FormLabel>
                <RadioGroup
                  aria-labelledby="radio-group"
                  defaultValue={CODIGO_CONFIRMACAO_VIA_EMAIL}
                  name="radio-buttons-group"
                >
                  <Typography variant="h6" sx={{ margin: '10px' }}>
                    Enviar código de confirmação via
                  </Typography>
                  {apiEmailOk && dadosCliente.email && dadosCliente.email !== ' ' && (
                    <FormControlLabel
                      value={'1'}
                      control={<Radio />}
                      onClick={() => setOpcao(CODIGO_CONFIRMACAO_VIA_EMAIL)}
                      label={`E-mail para ${mascararEmail(dadosCliente.email)}`}
                    />
                  )}

                  {apiZapOk &&
                    dadosCliente.telefone[0].trim() &&
                    dadosCliente.telefone[0] !== '00000000000' && (
                      <FormControlLabel
                        value={'2'}
                        control={<Radio />}
                        onClick={() => {
                          setOpcao(CODIGO_CONFIRMACAO_VIA_WHATSAPP);
                          setTelefoneSelecionado(dadosCliente.telefone[0]);
                        }}
                        label={`WhatsApp para ${mascararTelefone(dadosCliente.telefone[0])}`}
                      />
                    )}

                  {apiZapOk &&
                    dadosCliente.telefone[1].trim() &&
                    apiZapOk &&
                    dadosCliente.telefone[1] !== '00000000000' && (
                      <FormControlLabel
                        value={'3'}
                        control={<Radio />}
                        onClick={() => {
                          setOpcao(CODIGO_CONFIRMACAO_VIA_WHATSAPP);
                          setTelefoneSelecionado(dadosCliente.telefone[1]);
                        }}
                        label={`WhatsApp para ${mascararTelefone(dadosCliente.telefone[1])}`}
                      />
                    )}

                  {apiZapOk &&
                    dadosCliente.telefone[2].trim() &&
                    dadosCliente.telefone[2] !== '00000000000' && (
                      <FormControlLabel
                        value={'4'}
                        control={<Radio />}
                        onClick={() => {
                          setOpcao(CODIGO_CONFIRMACAO_VIA_WHATSAPP);
                          setTelefoneSelecionado(dadosCliente.telefone[2]);
                        }}
                        label={`WhatsApp para ${mascararTelefone(dadosCliente.telefone[2])}`}
                      />
                    )}

                  {apiZapOk &&
                    dadosCliente.telefone[3].trim() &&
                    dadosCliente.telefone[3] !== '00000000000' && (
                      <FormControlLabel
                        value={'5'}
                        control={<Radio />}
                        onClick={() => {
                          setOpcao(CODIGO_CONFIRMACAO_VIA_WHATSAPP);
                          setTelefoneSelecionado(dadosCliente.telefone[3]);
                        }}
                        label={`WhatsApp para ${mascararTelefone(dadosCliente.telefone[3])}`}
                      />
                    )}

                  {apiZapOk &&
                    dadosCliente.telefone[4].trim() &&
                    dadosCliente.telefone[4] !== '00000000000' && (
                      <FormControlLabel
                        value={'6'}
                        control={<Radio />}
                        onClick={() => {
                          setOpcao(CODIGO_CONFIRMACAO_VIA_WHATSAPP);
                          setTelefoneSelecionado(dadosCliente.telefone[4]);
                        }}
                        label={`WhatsApp para ${mascararTelefone(dadosCliente.telefone[4])}`}
                      />
                    )}
                </RadioGroup>
              </FormControl>
            </Grid>

            {loading && (
              <Box sx={{ width: '100%', textAlign: 'center' }}>
                <h2>Aguarde, o código esta sendo enviado ...</h2>
                <LinearProgress />
              </Box>
            )}

            <Grid item xs={6}>
              {pedirCodigo && (
                <TextFieldGeneric
                  label={'Código de confirmação'}
                  value={codigoConfirmacao}
                  onChange={(value) => {
                    setCodigoConfirmacao(value);
                  }}
                />
              )}
            </Grid>
          </Grid>

          {!pedirCodigo && (
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Button
                variant="contained"
                onClick={() => {
                  enviarCodigoVerficacao();
                }}
              >
                <Typography fontSize={12}>Enviar código</Typography>
              </Button>
            </Grid>
          )}

          {pedirCodigo && (
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Button
                variant="contained"
                onClick={() => {
                  confirmarCodigoVerificacao();
                }}
              >
                <Typography fontSize={12}>Confirmar</Typography>
              </Button>
            </Grid>
          )}
        </div>
      )}
    </ModalGeneric>
  );
};

export default ModalConfirmarCadastro;
