import { TextField } from '@material-ui/core';
import React, { KeyboardEvent } from 'react';
import NumberFormat from 'react-number-format';

type Variant = 'outlined' | 'standard' | 'filled' | undefined;

type Props = {
  value: number | string;
  onChange: (valor: number) => void;
  label: string;
  maxLength?: number;
  submit?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  fullWidth?: boolean;
  type?: 'money' | 'number';
  decimalScale?: number;
  prefix?: string;
  suffix?: string;
  mask?: string;
  variant?: Variant;
  textAlign?: 'center' | 'left' | 'right';
  autoSelect?: boolean;
  style?: React.CSSProperties;
  disabled?: boolean;
  autoFocus?: boolean;
  inputProps?: any;
  onKeyDown?: (event?: KeyboardEvent<HTMLElement>) => void;
  onBlur?: () => void;
};

const EdNumberField: React.FC<Props> = (props) => {
  const {
    value,
    maxLength,
    submit,
    fullWidth,
    type,
    onChange,
    decimalScale,
    prefix,
    suffix,
    variant,
    textAlign,
    autoSelect,
    label,
    disabled,
    autoFocus,
    onKeyDown,
    onBlur,
    ...other
  } = props;

  let newVariant: Variant = variant;
  if (!newVariant) {
    newVariant = 'outlined';
  } else if (newVariant === 'standard') {
    newVariant = undefined;
  }

  let newValue = value;
  if (typeof value !== 'string' && typeof value !== 'number') {
    newValue = 0;
  }
  if (typeof newValue === 'string') {
    newValue = parseFloat(newValue);
    if (!newValue) {
      newValue = 0;
    }
  }

  return (
    <NumberFormat
      isNumericString
      autoFocus={autoFocus}
      prefix={type === 'money' ? 'R$ ' : prefix}
      suffix={suffix}
      decimalScale={type === 'money' ? 2 : decimalScale}
      decimalSeparator={','}
      thousandSeparator={'.'}
      fixedDecimalScale={type === 'money' ? true : false}
      value={newValue}
      fullWidth={fullWidth === false ? false : true}
      variant={newVariant}
      label={label}
      {...other}
      customInput={TextField}
      inputProps={{
        maxLength,
        style: {
          textAlign
        }
      }}
      disabled={disabled}
      onFocus={(e) => {
        e.target.select();
      }}
      onBlur={onBlur}
      onValueChange={(value) => {
        if (!value.floatValue) {
          value.floatValue = 0;
        }
        onChange(value.floatValue);
      }}
      onKeyDown={(event) => {
        if (submit && event.key === 'Enter') {
          submit(event);
        } else if (onKeyDown) {
          onKeyDown(event);
        }
      }}
      {...other}
    />
  );
};

export default EdNumberField;
