import React, { useState } from 'react';
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';
import { mudarIluminacao } from '../../helper';
import ConfirmDialogGeneric from './ConfirmDialogGeneric';
import ClockLoader from 'react-spinners/ClockLoader';

type Props = {
  titulo: string;
  value: string;
  icon?: React.ReactNode;
  backgroundColor: string;
  color: string;
  onClick?: () => void;
  telaDeNavegacao?: string;
  loading?: boolean;
};

const CardTotalizadores: React.FC<Props> = ({
  titulo,
  value,
  icon,
  backgroundColor,
  color,
  onClick,
  telaDeNavegacao,
  loading
}) => {
  const lighterColor = mudarIluminacao(backgroundColor, 0.2);

  const [open, setOpen] = useState(false);

  return (
    <Grid item xs={12} sm={12} md={4} style={{ padding: '5px' }}>
      <Card
        sx={{
          backgroundColor: backgroundColor,
          color: color,
          borderRadius: '12px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
          '&:hover': {
            transform: 'scale(1.01)',
            cursor: 'pointer',
            boxShadow: '0 6px 12px rgba(0, 0, 0, 0.3)'
          },
          height: '100px'
        }}
        onClick={() => {
          setOpen(true);
        }}
      >
        <CardContent
          sx={{
            padding: '0',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%'
          }}
        >
          <Box
            sx={{
              backgroundColor: lighterColor,
              borderRadius: '12px 12px 0 0',
              padding: '5px',
              textAlign: 'start'
            }}
          >
            <Typography
              sx={{
                fontWeight: 'bold',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                marginLeft: '10px'
              }}
            >
              {titulo}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
              height: '100%',
              padding: '10px'
            }}
          >
            {loading ? <ClockLoader color={color} /> : icon}
            <Typography
              sx={{
                fontWeight: 'bold',
                fontSize: '25px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
            >
              {loading ? null : value}
            </Typography>
          </Box>
        </CardContent>
      </Card>
      <ConfirmDialogGeneric
        open={open}
        cabecalho={''}
        texto={`Gostaria de acessar a tela de ${telaDeNavegacao}?`}
        txtRespostaNegativa="Não"
        txtRespostaPositiva="Sim"
        onResposta={(resposta) => {
          if (resposta && onClick) {
            onClick();
            setOpen(false);
          } else {
            setOpen(false);
          }
        }}
      />
    </Grid>
  );
};

export default CardTotalizadores;
