import MenuIcon from '@mui/icons-material/Menu';
import { Grid, Hidden } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';

import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useSessao } from '../../redux/sessao/sessaoHooks';
import CarrinhoPedido from '../../Pages/Pedido/CarrinhoPedido';

const drawerWidth = 200;

type Props = {
  open: boolean;
  setOpen: (x: boolean) => void;
};

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

const Header: React.FC<Props> = (props) => {
  const { open, setOpen } = props;

  const [sessao] = useSessao();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const corEmpresa = '#2196f3';

  return (
    <div>
      <AppBar position="fixed" open={open} style={{ padding: '5px', backgroundColor: corEmpresa }}>
        <Grid container alignItems={'center'}>
          <Grid item>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginLeft: 1,
                ...(open && { display: 'none' })
              }}
            >
              <MenuIcon />
            </IconButton>
          </Grid>

          <Hidden mdDown>
            <Typography
              variant="h6"
              noWrap
              component="div"
              style={{ flexGrow: 1, textAlign: 'center' }}
            >
              {sessao.empresa?.sigla}
            </Typography>
          </Hidden>

          {!open && (
            <>
              <Hidden mdUp>
                <Grid item xs>
                  <Typography variant="h6" style={{ flexGrow: 1, textAlign: 'center' }}>
                    {sessao.empresa?.sigla}
                  </Typography>
                </Grid>
              </Hidden>

              {sessao?.empresa?.clientePodeFazerPedido && <CarrinhoPedido open={open} />}
            </>
          )}
        </Grid>
      </AppBar>
    </div>
  );
};

export default Header;
