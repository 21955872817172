import ModalGeneric from '../../Components/generic/ModalGeneric';
import React from 'react';
import { Container, Typography, Button } from '@mui/material';
import { keyframes } from '@mui/system';
import PEDIDO_CONCLUIDO from '../../../src/assets/pedido-concluido.png';

type Props = {
  numeroPedido: string;
  onClose: () => void;
  open: boolean;
};

const ModalPedidoConcluido: React.FC<Props> = ({ onClose, open, numeroPedido }) => {
  const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

  // 'https://img.freepik.com/fotos-gratis/caixa-de-papelao-com-lista-de-verificacao-de-carga-e-lapis_107791-16644.jpg?t=st=1721747934~exp=1721751534~hmac=c7d61cbf7d49a0fac220fdb624156d5d0a46ee17bde00fabfa28e89bf230aebb&w=740',
  // 'https://img.freepik.com/vetores-gratis/receber-pedidos-por-telefone-central-de-atendimento-da-loja-suporte-ao-cliente-pedido-facil-entrega-rapida-servico-comercial-personagem-de-desenho-animado-do-operador-de-call-center_335657-2564.jpg?ga=GA1.1.279453951.1719545178&semt=ais_user',
  // 'https://img.freepik.com/vetores-gratis/ilustracao-de-personagens-de-pessoas-com-pacotes-para-envio_53876-59858.jpg?t=st=1721748856~exp=1721752456~hmac=4188129edf682ef6e61fd020b3ad83e239beb3c291302c6ba481576aaac8f76e&w=996'

  return (
    <ModalGeneric open={open} onClose={onClose} hideCloseButton>
      <Container
        maxWidth="sm"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '80vh',
          textAlign: 'center',
          p: 4,
          animation: `${fadeIn} 0.5s ease-in-out`
        }}
      >
        <img
          style={{
            height: '40%',
            width: 'auto',
            objectFit: 'contain',
            marginBottom: '20px'
          }}
          src={PEDIDO_CONCLUIDO}
          alt=""
        />
        <Typography
          variant="h4"
          gutterBottom
          sx={{ fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', mb: 2 }}
        >
          Pedido enviado com sucesso!
        </Typography>
        <Typography variant="body1" sx={{ mb: 4 }}>
          {numeroPedido && numeroPedido !== ''
            ? `Seu pedido n° ${numeroPedido} foi enviado para análise. `
            : 'Seu pedido foi enviado para análise. '}
          Em breve, um de nossos representantes entrará em contato para efetuar a venda.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          href="/"
          sx={{
            mt: 2,
            bgcolor: 'primary.main',
            '&:hover': {
              bgcolor: 'primary.dark',
              transform: 'scale(1.05)',
              transition: '0.3s'
            },
            borderRadius: 8,
            padding: '10px 20px'
          }}
        >
          Voltar para a Página Inicial
        </Button>
      </Container>
    </ModalGeneric>
  );
};

export default ModalPedidoConcluido;
