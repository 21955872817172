import apiAutenticacao from '../../api/apiAutenticacao';
import { RootAppState } from '../store';
import { SessaoState } from '../reduxTypes';
import { useDispatch, useSelector } from 'react-redux';
import { loginAction, logoutAction } from './sessaoActions';

export const useSessao = (): [
  SessaoState,
  (login: string, senha: string) => Promise<void>,
  () => Promise<void>
] => {
  const sessao = useSelector((state: RootAppState) => state.sessao);
  const dispatch = useDispatch();

  const login = async (login: string, senha: string) => {
    const sessao = await apiAutenticacao.login(login, senha);
    console.log('sessao', sessao);
    const action = loginAction(sessao);
    dispatch(action);
  };

  const logout = async () => {
    const action = logoutAction();
    dispatch(action);
    localStorage.clear();
  };

  return [sessao, login, logout];
};

export const useClienteLogado = () => {
  const [sessao] = useSessao();
  return sessao.cliente;
};

export const useEmpresa = () => {
  const [sessao] = useSessao();
  return sessao.empresa;
};
