import {
  Box,
  Button,
  Grid,
  InputAdornment,
  LinearProgress,
  TextField,
  Typography
} from '@mui/material';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ModalGeneric from '../../Components/generic/ModalGeneric';
import { useState } from 'react';
import { toast } from 'react-toastify';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import { InformacoesCliente } from '../../types/EdClienteTypes';
import ModalConfirmarCadastro from './ModalConfirmarCadastro';
import LogoEmpresa from '../../Components/generic/LogoEmpresa';
import apiCliente from '../../api/apiCliente';
import apiAutenticacao from '../../api/apiAutenticacao';

type Props = {
  open: boolean;
  onClose: () => void;
};

const ModalCadastroUsuario: React.FC<Props> = (props) => {
  const { open, onClose } = props;

  const [loading, setLoading] = useState(false);
  const [documento, setDocumento] = useState('');

  const classes = useStyles();
  const [clienteEncontrado, setClienteEncontrado] = useState<InformacoesCliente | null>(null);
  const [openModalConfirmarCadastro, setOpenModalConfirmarCadastro] = useState(false);

  const [apiEmailOk, setApiEmailOk] = useState(false);
  const [apiZapOk, setApiZapOk] = useState(false);
  const [semOpcaoDisponivel, setSemOpcaoDisponivel] = useState(false);

  const getInformacoesCliente = async () => {
    try {
      setLoading(true);
      if (!documento) {
        toast.error('Informe o CPF ou CNPJ');
        return;
      }
      const res = await apiCliente.buscarInformacoesCliente(documento);
      if (
        !res.email &&
        (!res.telefone ||
          ((res.telefone[0].trim() === '' || res.telefone[0] === '00000000000') &&
            (res.telefone[1].trim() === '' || res.telefone[1] === '00000000000') &&
            (res.telefone[2].trim() === '' || res.telefone[2] === '00000000000') &&
            (res.telefone[3].trim() === '' || res.telefone[3] === '00000000000') &&
            (res.telefone[4].trim() === '' || res.telefone[4] === '00000000000')))
      ) {
        setLoading(false);
        setSemOpcaoDisponivel(true);
        return;
      }

      if (res && res.nome) {
        const emailPromisse = await apiAutenticacao.getStatusEmail();
        const zapPromisse = await apiAutenticacao.getStatusWhatsApp();
        const [resEmail, resZap] = await Promise.all([emailPromisse, zapPromisse]);
        setApiEmailOk(resEmail);
        setApiZapOk(resZap);

        setClienteEncontrado(res);
        setOpenModalConfirmarCadastro(true);
      }
      setLoading(false);
    } catch (erro: any) {
      setLoading(false);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      console.log('erro ao fazer cadastro', erro);
      toast.error(erro.response ? erro.response.data : 'Erro ao fazer cadastro');
    }
  };

  if (loading) {
    return (
      <ModalGeneric
        open={open}
        onClose={() => {
          onClose();
        }}
      >
        <Box sx={{ width: '100%', textAlign: 'center' }}>
          <h2>Verificando informações ...</h2>
          <LinearProgress />
        </Box>
      </ModalGeneric>
    );
  }

  return (
    <ModalGeneric
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <LogoEmpresa />

      <div className={classes.form}>
        <Typography component={'div'} variant="h5" color={'#428CFF'}>
          Seja Bem Vindo!
        </Typography>

        <Typography
          component={'div'}
          variant="h6"
          color={'#428CFF'}
          style={{ marginBottom: '20px' }}
        >
          Registre-se
        </Typography>

        <TextField
          label={'CPF / CNPJ'}
          style={{ width: 250, margin: '15px' }}
          variant={'outlined'}
          className={classes.input}
          value={documento}
          onChange={(event) => {
            setDocumento(event.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <HistoryEduIcon />
              </InputAdornment>
            )
          }}
        />

        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Button
            variant="contained"
            onClick={() => {
              getInformacoesCliente();
            }}
          >
            <Typography fontSize={12}>Cadastrar</Typography>
          </Button>
        </Grid>

        {clienteEncontrado && openModalConfirmarCadastro && (
          <ModalConfirmarCadastro
            open={openModalConfirmarCadastro}
            dadosCliente={clienteEncontrado}
            apiEmailOk={apiEmailOk}
            apiZapOk={apiZapOk}
            onClose={() => {
              setOpenModalConfirmarCadastro(false);
            }}
          />
        )}
      </div>

      {semOpcaoDisponivel && (
        <ModalGeneric open={semOpcaoDisponivel} onClose={() => setSemOpcaoDisponivel(false)}>
          <LogoEmpresa />

          <div style={{ margin: '15px', textAlign: 'center' }}>
            <Typography fontWeight={'bold'}>
              Não foram encontradas opções em seu cadastro para que seja possível verificar o mesmo.
              Entre em contato com a empresa, para regularizar seu cadastro.
            </Typography>
          </div>
        </ModalGeneric>
      )}
    </ModalGeneric>
  );
};

export default ModalCadastroUsuario;
const useStyles = makeStyles((theme) =>
  createStyles({
    input: {
      color: '#fff',
      borderColor: '#fff',
      minWidth: '250px',
      marginBottom: '30px'
    },
    form: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      marginBottom: '30px',
      height: 'auto'
    }
  })
);
