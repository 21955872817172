import { JoinOptions } from 'typeorm';

type FindOptionsWhere<Entity> = { [P in keyof Entity]?: any };

export interface EdFindOneOptions<Entity = any> {
  /**
   * Specifies what columns should be retrieved.
   */
  select?: (keyof Entity)[];
  /**
   * Simple condition that should be applied to match entities.
   */
  where?: FindOptionsWhere<Entity> | FindOptionsWhere<Entity>[]; // | ObjectLiteral | string;
  /**
   * Indicates what relations of entity should be loaded (simplified left join form).
   */
  relations?: string[];
  /**
   * Specifies what relations should be loaded.
   */
  join?: JoinOptions;
  /**
   * Order, in which entities should be ordered.
   */
  order?: {
    [P in keyof Entity]?: 'ASC' | 'DESC';
  };
  /**
   * Enables or disables query result caching.
   */
  cache?:
    | boolean
    | number
    | {
        id: any;
        milliseconds: number;
      };
  /**
   * Indicates what locking mode should be used.
   */
  lock?:
    | {
        mode: 'optimistic';
        version: number | Date;
      }
    | {
        mode:
          | 'pessimistic_read'
          | 'pessimistic_write'
          | 'dirty_read'
          | 'pessimistic_partial_write'
          | 'pessimistic_write_or_fail';
      };
  /**
   * Indicates if soft-deleted rows should be included in entity result.
   */
  withDeleted?: boolean;
  /**
   * If sets to true then loads all relation ids of the entity and maps them into relation values (not relation objects).
   * If array of strings is given then loads only relation ids of the given properties.
   */
  loadRelationIds?:
    | boolean
    | {
        relations?: string[];
        disableMixedMap?: boolean;
      };
  /**
   * Indicates if eager relations should be loaded or not.
   * By default they are loaded when find methods are used.
   */
  loadEagerRelations?: boolean;
  /**
   * If this is set to true, SELECT query in a `find` method will be executed in a transaction.
   */
  transaction?: boolean;
}

export interface EdFindManyOptions<Entity = any> extends EdFindOneOptions<Entity> {
  skip?: number;
  take?: number;
}

export interface FindOneReq<Entity> {
  select?: (keyof Entity)[];
  where?: EdWhere<Entity>;
  relations?: string[];
  order?: {
    [P in keyof Entity]?: 'ASC' | 'DESC';
  };
}

export interface FindManyReq<Entity> extends FindOneReq<Entity> {
  skip?: number;
  take?: number;
}

export type EdWhereObject<Entity> = Partial<{
  [k in keyof Entity]: Entity[k] | QueryObjects | undefined;
}>;

export type EdWhere<Entity> = EdWhereObject<Entity> | EdWhereObject<Entity>[] | undefined;

export type QueryObjects = SimpleQueryObject | BetweenQueryObject | InQueryObject;

type SimpleQueryObject = {
  type: 'LIKE' | 'GREATER' | 'LESS' | 'NOT' | 'GREATER_EQUAL' | 'LESS_EQUAL';
  value: string | number;
};

type InQueryObject = {
  type: 'IN';
  value: string[];
};

type BetweenQueryObject = {
  type: 'BETWEEN';
  firstValue: string | number;
  secondValue: string | number;
};

export const EdLike = (value: string) => {
  return {
    type: 'LIKE',
    value
  } as SimpleQueryObject;
};

export const EdGreaterThan = (value: string) => {
  return {
    type: 'GREATER',
    value
  } as SimpleQueryObject;
};

export const EdGreaterThanOrEqual = (value: string) => {
  return {
    type: 'GREATER_EQUAL',
    value
  } as SimpleQueryObject;
};

export const EdLessThan = (value: string) => {
  return {
    type: 'LESS',
    value
  } as SimpleQueryObject;
};

export const EdLessThanOrEqual = (value: string) => {
  return {
    type: 'LESS_EQUAL',
    value
  } as SimpleQueryObject;
};

export const EdNot = (value: string) => {
  return {
    type: 'NOT',
    value
  } as SimpleQueryObject;
};

export const EdIn = (value: string[] | number[]) => {
  return {
    type: 'IN',
    value
  } as InQueryObject;
};

export const EdBetween = (firstValue: string | number, secondValue: string | number) => {
  return {
    type: 'BETWEEN',
    firstValue,
    secondValue
  } as BetweenQueryObject;
};

export type QueryAdicional<T> = EdWhereObject<T> | undefined;
