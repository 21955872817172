import * as React from 'react';
import Box from '@mui/material/Box';

import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import {
  Badge,
  Button,
  CardMedia,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu
} from '@mui/material';
import { useSessao } from '../../redux/sessao/sessaoHooks';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import DeleteIcon from '@mui/icons-material/Delete';
import CARRINHO_VAZIO from '../../../src/assets/carrinho-de-compras-vazio.png';
import { useEffect, useState } from 'react';
import apiItensCarrinho from '../../api/apiItensCarrinho';
import ConfirmDialogGeneric from '../../Components/generic/ConfirmDialogGeneric';
import { ProdutosCarrinhoEdCliente } from '../../types/NovoPedidoTypes';
import SEM_FOTO from '../../../src/assets/sem-foto.jpg';
import ModalPedidoConcluido from './ModalPedidoConcluido';
import apiPedidoLV from '../../api/apiPedidoLV';
import { mostrarToast } from '../../helper';
import { Typography } from '@material-ui/core';
import TituloCarrinhoPedido from './TituloCarrinhoPedido';

type Props = {
  open: boolean;
};

export type PedidoProdutoLVType = {
  codigo_produto: string;
  quantidade: number;
  valor_unitario: number;
  total: number;
  loja: string;
};

const CarrinhoPedido: React.FC<Props> = (props) => {
  const { open } = props;

  const [sessao] = useSessao();
  const [anchorCarrinho, setAnchorCarrinho] = React.useState<null | HTMLElement>(null);
  const [openConfirmDialogRemover, setOpenConfirmDialogRemover] = useState(false);
  const [openConfirmDialogFinalizar, setOpenConfirmDialogFinalizar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [itensCarrinho, setItensCarrinho] = useState<ProdutosCarrinhoEdCliente[]>([]);
  const [itemSelecionado, setItemSelecionado] = useState<number>(0);
  const [totalProdutos, setTotalProdutos] = useState(0);
  const navigate = useNavigate();
  const openMenuCarrinho = Boolean(anchorCarrinho);
  const codigoCliente = sessao.cliente?.codigo;
  const [numeroPedidoRetornado, setNumeroPedidoRetornado] = useState('');

  const [openModalPedidoConcluido, setOpenModalPedidoConcluido] = useState(false);

  const handleClickCarrinho = async (event: React.MouseEvent<HTMLElement>) => {
    setAnchorCarrinho(event.currentTarget);
    await buscarItensCarrinho();
  };

  const handleCloseCarrinho = () => {
    setAnchorCarrinho(null);
  };

  const buscarItensCarrinho = async () => {
    try {
      setLoading(true);

      const res = await apiItensCarrinho.getManyComMidia({
        where: { codigo_cliente: codigoCliente },
        relations: ['produto']
      });

      if (res && res.length > 0) {
        const total = res.reduce(
          (soma, item) => (soma += item.quantidade * item.produto?.estoques[0]?.precoVenda),
          0
        );
        setTotalProdutos(total);

        setItensCarrinho(res);
      } else {
        setItensCarrinho([]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const removerItemCarrinho = async (idCarrinho: number) => {
    try {
      setLoading(true);

      await apiItensCarrinho.delete({
        id: idCarrinho
      });

      await buscarItensCarrinho();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const finalizarPedido = async () => {
    try {
      setLoading(true);

      const codigoCliente = sessao.cliente?.codigo ?? '';

      const res = await apiPedidoLV.finalizaPedidoEdCliente(codigoCliente);

      if (res?.status === 'success') {
        setNumeroPedidoRetornado(res?.numeroPedido ?? '');
        await buscarItensCarrinho();

        setOpenModalPedidoConcluido(true);
      } else {
        mostrarToast(res?.message, 'warning');
      }
    } catch (err: any) {
      mostrarToast(err.response.data, 'error');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (sessao.empresa?.clientePodeFazerPedido) {
      buscarItensCarrinho();
    }
     
  }, [sessao.empresa?.clientePodeFazerPedido]);

  return (
    <div>
      {sessao.empresa?.clientePodeFazerPedido && (
        <Tooltip title="Carrinho de compras" style={{ marginRight: '15px' }} arrow>
          <IconButton
            edge="end"
            onClick={handleClickCarrinho}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'carrinho-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Badge badgeContent={itensCarrinho?.length} max={9} color="warning">
              <Avatar
                sx={{
                  width: 40,
                  height: 40,
                  backgroundColor: '#1976d2',
                  padding: '8px',
                  borderRadius: '50%',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)'
                }}
              >
                <ShoppingCartIcon
                  style={{
                    fontSize: '24px',
                    color: 'white'
                  }}
                />
              </Avatar>
            </Badge>
          </IconButton>
        </Tooltip>
      )}

      <Menu
        anchorEl={anchorCarrinho}
        id="carrinho-menu"
        open={openMenuCarrinho}
        onClose={handleCloseCarrinho}
        onClick={handleCloseCarrinho}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <TituloCarrinhoPedido />
        <Divider light />

        <Box
          sx={{
            height: '300px',
            width: '400px',

            padding: '10px',
            overflowY: 'auto',
            overflowX: 'hidden',
            '&::-webkit-scrollbar': {
              width: '5px'
            },
            '&::-webkit-scrollbar-track': {
              background: 'transparent'
            },
            '&::-webkit-scrollbar-thumb': {
              background: 'transparent'
            },
            '&:hover': {
              '&::-webkit-scrollbar-track': {
                background: 'white'
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#2196f3',
                borderRadius: '100px'
              }
            }
          }}
        >
          {itensCarrinho?.length > 0 && (
            <List dense>
              {itensCarrinho.length > 0 &&
                itensCarrinho?.map((item, index) => (
                  <>
                    <Box
                      key={item.produto?.codigo}
                      sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}
                    >
                      <ListItem
                        key={item.produto?.codigo}
                        sx={{ width: { md: '400px', xs: '330px' } }}
                      >
                        <ListItemAvatar>
                          <Avatar>
                            <img
                              src={item.urlImagem === 'SEM_FOTO' ? SEM_FOTO : item.urlImagem}
                              alt={item?.produto?.descricao}
                              style={{ width: '40px', height: '40px' }}
                            />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            !item?.produto?.descricaoReduz ||
                            item?.produto?.descricaoReduz?.trim() === ''
                              ? item?.produto?.descricao?.substring(0, 40)
                              : item?.produto?.descricaoReduz
                          }
                          secondary={`Qtd: ${
                            item.quantidade
                          } | R$ ${item?.produto?.estoques[0].precoVenda.toFixed(2)}`}
                        />
                        <Tooltip title="Remover produto" arrow>
                          <IconButton
                            edge="end"
                            onClick={(e) => {
                              setItemSelecionado(item.id);
                              setOpenConfirmDialogRemover(true);
                              e.stopPropagation();
                            }}
                          >
                            <DeleteIcon color="error" fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </ListItem>
                    </Box>

                    <Divider />
                  </>
                ))}
            </List>
          )}
          {itensCarrinho?.length === 0 && (
            <CardMedia
              image={CARRINHO_VAZIO}
              sx={{
                height: { md: '80%', xs: '80%' },
                width: { md: '100%', xs: '90%' },
                objectFit: 'contain',
                marginRight: { md: '10px', xs: '0px' }
              }}
            />
          )}
        </Box>

        <Divider light />
        {itensCarrinho?.length > 0 && !loading && (
          <Box sx={{ padding: '10px' }}>
            <Typography style={{ fontWeight: 'bold' }}>
              Total: R$ {totalProdutos?.toFixed(2)}
            </Typography>
            <Button
              onClick={async () => {
                setOpenConfirmDialogFinalizar(true);
              }}
              style={{
                marginTop: '10px',
                width: '100%',
                color: 'white',
                backgroundColor: '#2196f3',
                fontWeight: 'bold'
              }}
            >
              Finalizar Pedido
            </Button>
          </Box>
        )}

        <Box sx={{ paddingLeft: '10px', paddingRight: '10px' }}>
          <Button
            style={{
              width: '100%',
              color: '#2196f3',
              backgroundColor: '#c3e2fa',
              fontWeight: 'bold'
            }}
            onClick={(e) => {
              navigate('/novoPedido');
            }}
          >
            {itensCarrinho?.length > 0 ? 'Continuar Pedido' : 'Ver produtos disponiveis'}
          </Button>
        </Box>
      </Menu>

      <ConfirmDialogGeneric
        open={openConfirmDialogRemover}
        cabecalho={''}
        texto={'Deseja realmente remover o produto do carrinho?'}
        txtRespostaNegativa="Não"
        txtRespostaPositiva="Remover"
        onResposta={async (resposta) => {
          if (resposta && itemSelecionado) {
            await removerItemCarrinho(itemSelecionado);
            setOpenConfirmDialogRemover(false);
          } else {
            setOpenConfirmDialogRemover(false);
          }
        }}
      />
      <ConfirmDialogGeneric
        open={openConfirmDialogFinalizar}
        cabecalho={''}
        texto={'Deseja realmente finalizar o pedido?'}
        txtRespostaNegativa="Não"
        txtRespostaPositiva="Sim"
        onResposta={(resposta) => {
          if (resposta) {
            finalizarPedido();
            setOpenModalPedidoConcluido(true);
            setOpenConfirmDialogFinalizar(false);
          } else {
            setOpenConfirmDialogFinalizar(false);
          }
        }}
      />

      <ModalPedidoConcluido
        onClose={() => {}}
        open={openModalPedidoConcluido}
        numeroPedido={numeroPedidoRetornado}
      />
    </div>
  );
};

export default CarrinhoPedido;
