import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  TextField
} from '@mui/material';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ModalGeneric from '../../Components/generic/ModalGeneric';
import { useState } from 'react';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LogoEmpresa from '../../Components/generic/LogoEmpresa';
import CheckIcon from '@mui/icons-material/Check';
import { useSessao } from '../../redux/sessao/sessaoHooks';
import { toast } from 'react-toastify';
import apiAutenticacao from '../../api/apiAutenticacao';
import { loginAction } from '../../redux/sessao/sessaoActions';
import { useDispatch } from 'react-redux';

type Props = {
  open: boolean;
  onClose: () => void;
};

const ModalCadastrarSenha: React.FC<Props> = (props) => {
  const { open, onClose } = props;

  const [loading] = useState(false);
  const [sessao] = useSessao();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [senha, setSenha] = useState('');
  const [verSenha, setVerSenha] = useState(false);

  if (loading) {
    return (
      <ModalGeneric
        open={open}
        onClose={() => {
          onClose();
        }}
      >
        <Box sx={{ width: '100%', textAlign: 'center' }}>
          <h2>Verificando informações ...</h2>
          <LinearProgress />
        </Box>
      </ModalGeneric>
    );
  }

  const cadastrarSenha = async () => {
    try {
      if (!sessao.clienteUsuario) return;

      const res = await apiAutenticacao.atualizarSenha(senha);
      const action = loginAction(res);
      dispatch(action);
      toast.success(
        `Parabéns, ${
          sessao?.cliente?.nome.split(' ')[0]
        }! Sua senha foi cadastrada com sucesso. Boa jornada!`
      );
      onClose();
    } catch (erro: any) {
      toast.error('Aconteceu algum erro ao cadastrar a senha');
    }
  };
  return (
    <ModalGeneric open={open} onClose={() => {}}>
      <Grid container spacing={2} style={{ textAlign: 'center' }}>
        <Grid item xs={12}>
          <LogoEmpresa />
        </Grid>

        <Grid item xs={12}>
          <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
            Olá, {sessao?.cliente?.nome.split(' ')[0]}! 🌟 Seja muito bem-vindo ao nosso Portal de
            Relacionamento! Para garantir a segurança dos seus dados, precisamos que você cadastre
            uma senha exclusiva. Estamos aqui para tornar sua experiência incrível. Vamos lá, crie
            sua senha e aproveite todos os benefícios do nosso portal! 💻🔐
          </Alert>
        </Grid>

        <Grid item xs={12}>
          <TextField
            style={{ width: 250 }}
            label="Senha"
            value={senha}
            onChange={(event) => setSenha(event.target.value)}
            className={classes.input}
            type={verSenha ? 'text' : 'password'}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                cadastrarSenha();
              }
            }}
            disabled={loading}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setVerSenha(!verSenha)}
                    // onMouseDown={handleMouseDownPassword}
                  >
                    {verSenha ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Button variant={'contained'} style={{ margin: '20px' }} onClick={() => cadastrarSenha()}>
            Cadastrar senha
          </Button>
        </Grid>
      </Grid>
    </ModalGeneric>
  );
};

export default ModalCadastrarSenha;
const useStyles = makeStyles((theme) =>
  createStyles({
    input: {
      color: '#fff',
      borderColor: '#fff',
      minWidth: '250px',
      marginBottom: '30px'
    },
    message: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      marginBottom: '30px',
      height: 'auto'
    }
  })
);
