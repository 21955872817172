import ModalGeneric from '../../Components/generic/ModalGeneric';
import { useState, useEffect } from 'react';
import {
  DadosContaReceberBaixar,
  DadosListagemContasReceberCliente
} from '../../types/ContaReceberTypes';
import contaReceberApi from '../../api/apiContaReceber';
import { toast } from 'react-toastify';
import LinearProgress from '@mui/joy/LinearProgress';
import QRCode from 'qrcode.react';
import { makeStyles, Grid } from '@material-ui/core';
import { formatarDinheiro } from '../../helper';
import { ResCobrancaPixImediata } from '../../types/SicoobTypes';
import { useSessao } from '../../redux/sessao/sessaoHooks';
import ButtonGeneric from '../../Components/generic/ButtonGeneric';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import cobrancaPixApi from '../../api/apiCobrancaPix';
import dayjs from 'dayjs';
import { TIPO_PAGAMENTO_DUPLICATA_PIX, TIPO_PAGAMENTO_TOTAL } from '../../constants';

type Props = {
  open: boolean;
  onClose: () => void;
  dados: DadosListagemContasReceberCliente;
};

const ModalQrCode: React.FC<Props> = (props) => {
  const { open, onClose, dados } = props;
  const [gerandoQrCode, setGerandoQrCode] = useState(true);
  const [qrCode, setQRCode] = useState<ResCobrancaPixImediata | null>(null);
  const classes = useStyles();
  const [sessao] = useSessao();

  useEffect(() => {
    gerarQRCodePix(dados);
     
  }, []);

  useEffect(() => {
    async function consultarPagamento() {
      try {
        const res = await contaReceberApi.consultarCobrancaImediata(qrCode?.txid ?? '');
        if (res.status === 'CONCLUIDA') {
          if (res.pix && res.pix.length > 0) {
            await cobrancaPixApi.update(
              {
                fatura: dados.fatura
              },
              { dataPagamento: res.pix[0].horario }
            );
            await baixarContaReceber(res.valor.original);
            toast.success('Pagamento Confirmado');
            onClose();
          }
        }
      } catch (erro: any) {
        console.log('O sistema não conseguiu verificar o pagamento', erro);
      }
    }

    const id = setInterval(async () => {
      consultarPagamento();
    }, 5000);

    return () => {
      clearInterval(id);
    };
     
  }, [qrCode]);

  const gerarQRCodePix = async (parametros: DadosListagemContasReceberCliente) => {
    try {
      setGerandoQrCode(true);
      const res = await contaReceberApi.gerarQrCodePix({
        codigoCliente: parametros.codigoCliente,
        codigoEmpresa: parametros.codigoEmpresa,
        fatura: parametros.fatura,
        financeira: parametros.financeira,
        valor: parametros.valores.restante
      });
      setQRCode(res);
      setGerandoQrCode(false);
    } catch (erro: any) {
      setGerandoQrCode(false);
      toast.error('Aconteceu um erro ao gerar o QR CODE!');
      console.log('Aconteceu um erro ao gerar o QR CODE', erro);
    }
  };

  const baixarContaReceber = async (valorPago: string) => {
    const contaReceber = dados;

    const dadosBaixarContaReceber: DadosContaReceberBaixar = {
      codigoEmpresa: contaReceber.codigoEmpresa,
      codigoCliente: contaReceber.codigoCliente,
      financeira: contaReceber.financeira,
      fatura: contaReceber.fatura,
      valorPago: valorPago,
      dataPagamento: String(dayjs().date() + dayjs().month() + dayjs().year()),
      tipoPagamento: TIPO_PAGAMENTO_TOTAL,
      valorAcrescimoAnterior: formatarDinheiro(contaReceber.valores.valorAcrescimo),
      valorDesconto: formatarDinheiro(contaReceber.valores.valorDesconto),
      juros: formatarDinheiro(contaReceber.valores.juros),
      formaPagamento: TIPO_PAGAMENTO_DUPLICATA_PIX,
      valorDescontoAnterior: contaReceber.valores.valorDesconto.toString()
    };

    try {
      await contaReceberApi.baixarContaReceber(dadosBaixarContaReceber);
    } catch (error: any) {
      console.error(error);
    }
  };

  return (
    <ModalGeneric open={open} onClose={onClose}>
      {gerandoQrCode && (
        <div>
          <h1 style={{ textAlign: 'center' }}> Gerando QR CODE PIX . . .</h1>
          <LinearProgress color="primary" size="lg" value={50} variant="soft" />
        </div>
      )}
      {qrCode && !gerandoQrCode && (
        <>
          <div className={classes.boxQrCode}>
            <QRCode
              value={qrCode.brcode}
              size={250}
              style={{
                border: `4px solid #3498db`,
                height: 'auto',
                borderRadius: '10px',
                padding: '2rem'
              }}
            />
          </div>
          <h1 color="black">
            Valor a ser pago R$ {formatarDinheiro(Number(qrCode?.valor.original ?? 0.1))}
          </h1>
          <h2>para {sessao.empresa?.nome ?? ''}</h2>
          <h2>Chave PIX: {qrCode?.chave}</h2>
          <Grid
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '2rem'
            }}
          >
            <ButtonGeneric
              label={'Copiar QR CODE'}
              startIcon={<ContentCopyIcon />}
              variant="contained"
              style={{ color: '#3498db' }}
              onClick={() => {
                navigator.clipboard.writeText(qrCode?.brcode ?? '');
                toast.info('QR code Copiado!');
              }}
            ></ButtonGeneric>
          </Grid>
        </>
      )}
    </ModalGeneric>
  );
};

export default ModalQrCode;
const useStyles = makeStyles({
  root: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    opacity: '0.8'
  },
  between: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  boxQrCode: {
    display: 'flex',
    justifyContent: 'center',
    margin: '2rem 0px 2rem'
  },
  logo: { width: '25%', height: '100%' }
});
