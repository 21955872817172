import PedidoLV from '../Entity/PedidoLV';
import { FindManyReq, FindOneReq } from '../Entity/TypeormTypes';
import api from './apiEdCliente';

const PREFIX = 'api';
const ROUTE = 'edCliente/pedidoLV';

class ApiPedidoLV {
  async getOne(params: FindOneReq<PedidoLV>) {
    const res = await this.getMany({ ...params, take: 1 });
    return res[0] as PedidoLV | undefined;
  }

  async getMany(query: FindManyReq<PedidoLV>) {
    try {
      const res = await api.post<PedidoLV[]>(`/${PREFIX}/${ROUTE}/get`, { query });
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }
  async count(query: FindManyReq<PedidoLV>) {
    try {
      const res = await api.post<number>(`/${PREFIX}/${ROUTE}/count`, { query });
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async update(params: Partial<PedidoLV>, update: Partial<PedidoLV>) {
    try {
      const res = await api.put<PedidoLV>(`/${PREFIX}/${ROUTE}/`, { update }, { params });
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async insert(object: Partial<PedidoLV>) {
    try {
      const res = await api.post<PedidoLV>(`/${PREFIX}/${ROUTE}`, { object });
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async delete(params: Partial<PedidoLV>) {
    try {
      const res = await api.delete<PedidoLV>(`/${PREFIX}/${ROUTE}/`, { params });
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async finalizaPedidoEdCliente(codigoCliente: string) {
    try {
      const res = await api.post<{
        status: string;
        message: string;
        numeroPedido?: string;
      }>(`/${PREFIX}/${ROUTE}/finalizaPedidoEdCliente`, {
        codigoCliente
      });

      const data = res.data;
      return data;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  }
}

const apiPedidoLV = new ApiPedidoLV();
export default apiPedidoLV;
