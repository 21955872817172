import { Typography } from '@mui/material';
import LogoEdilSystem from '../../assets/edilsystem-logo.png';

const Footer: React.FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        fontSize: '16px',
        margin: '0px 0px 10px 0px',
        flexDirection: 'column'
      }}
    >
      <Typography>Desenvolvido por</Typography>
      <img src={LogoEdilSystem} alt="LogoEdilSystem" width={85} />
    </div>
  );
};

export default Footer;
