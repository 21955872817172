import { DadosPublicosEmpresa } from '../Entity/Empresa';
import api, { getUrlAtiva } from './apiEdCliente';

const PREFIX = 'api';
const ROUTE = 'empresa';

class ApiEmpresa {
  // DEPRECATED - APAGAR NO FUTURO E UTILIZAR getDadosPublicosEmpresa
  async getLogoEmpresa() {
    const res = await api.get<string>(`/${PREFIX}/${ROUTE}/getLogoEmpresa`);
    const data = res.data;
    if (data.startsWith('http')) {
      return data;
    } else {
      const url = getUrlAtiva();
      return `${url}${data}`;
    }
  }

  async getDadosPublicosEmpresa() {
    const res = await api.get<DadosPublicosEmpresa>(`/${PREFIX}/${ROUTE}/getDadosPublicosEmpresa`);

    const data = res.data;
    const url = getUrlAtiva();
    if (data.urlImagem) {
      data.urlImagem = data.urlImagem?.startsWith('http')
        ? data.urlImagem
        : `${url}${data.urlImagem}`;
    }
    return data;
  }
}

const apiEmpresa = new ApiEmpresa();

export default apiEmpresa;
