import PedidoProdutoLV from '../Entity/PedidoProdutoLV';
import { FindManyReq, FindOneReq } from '../Entity/TypeormTypes';
import { ProdutosCarrinhoEdCliente } from '../types/NovoPedidoTypes';
import api from './apiEdCliente';

const PREFIX = 'api';
const ROUTE = 'edCliente/pedidoProdutoLV';

type PedidoProdutoLVType = {
  id_pedido: string;
  codigo_produto: string;
  quantidade: number;
  valor_unitario: number;
  total: number;
  loja: string;
};

class ApiPedidoProdutoLV {
  async getOne(params: FindOneReq<PedidoProdutoLV>) {
    const res = await this.getMany({ ...params, take: 1 });
    return res[0] as PedidoProdutoLV | undefined;
  }

  async getMany(query: FindManyReq<PedidoProdutoLV>) {
    try {
      const res = await api.post<PedidoProdutoLV[]>(`/${PREFIX}/${ROUTE}/get`, { query });
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async getManyComMidia(query: FindManyReq<PedidoProdutoLV>, currentURL: string) {
    try {
      const res = await api.post<ProdutosCarrinhoEdCliente[]>(`/${PREFIX}/${ROUTE}/obterItens`, {
        query
      });
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }
  async count(query: FindManyReq<PedidoProdutoLV>) {
    try {
      const res = await api.post<number>(`/${PREFIX}/${ROUTE}/count`, { query });
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async update(params: Partial<PedidoProdutoLV>, update: Partial<PedidoProdutoLV>) {
    try {
      const res = await api.put<PedidoProdutoLV>(`/${PREFIX}/${ROUTE}/`, { update }, { params });
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async insert(object: Partial<PedidoProdutoLV> | PedidoProdutoLV[] | PedidoProdutoLVType[]) {
    try {
      const res = await api.post<PedidoProdutoLV>(`/${PREFIX}/${ROUTE}`, { object });
      const data = res.data;

      console.log('object', object);

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async delete(params: Partial<PedidoProdutoLV>) {
    try {
      const res = await api.delete<PedidoProdutoLV>(`/${PREFIX}/${ROUTE}/`, { params });
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }
}

const apiPedidoProdutoLV = new ApiPedidoProdutoLV();
export default apiPedidoProdutoLV;
