import { Button } from '@material-ui/core';

type Props = {
  label: string;
  loading?: boolean;
  onClick: () => void;
  endIcon?: React.ReactNode;
  startIcon?: React.ReactNode;
  variant?: 'text' | 'outlined' | 'contained';
  style?: React.CSSProperties;
};

const ButtonGeneric: React.FC<Props> = (props) => {
  const { label, onClick, endIcon, variant, style, startIcon } = props;

  return (
    <>
      <Button
        variant={variant ?? 'contained'}
        onClick={onClick}
        endIcon={endIcon}
        style={style}
        startIcon={startIcon}
      >
        {label}
      </Button>
    </>
  );
};

export default ButtonGeneric;
