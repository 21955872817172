import { Pedido } from '../Entity/Pedido';
import { FindManyReq } from '../Entity/TypeormTypes';
import { PedidoCompletoEDClienteType, ReqGetTotalPedidos } from '../types/PedidoTypes';
import api from './apiEdCliente';

const PREFIX = 'api';
const ROUTE = 'edCliente/pedido';

class ApiPedido {
  async getOne(params: FindManyReq<Pedido>) {
    const pedidos = await this.getMany({ ...params, take: 1 });
    return pedidos[0] as Pedido | undefined;
  }

  async getMany(query: FindManyReq<Pedido>) {
    try {
      const res = await api.post<Pedido[]>(`/${PREFIX}/${ROUTE}/get`, { query });
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async count(query: FindManyReq<Pedido>) {
    try {
      const res = await api.post<number>(`/${PREFIX}/${ROUTE}/count`, { query });
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async getOneCompleto(numeroPedido: string, buscarItens = true, codigoEmpresa: string) {
    const params = { numeroPedido, buscarItens, codigoEmpresa };
    try {
      const res = await api.get<PedidoCompletoEDClienteType>(
        `/${PREFIX}/${ROUTE}/getPedidoCompleto`,
        {
          params
        }
      );
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async imprimirDav(numeroPedido: string) {
    if (!numeroPedido) {
      return '';
    }

    await api.get(`/${PREFIX}/${ROUTE}//imprimirDav?numeroPedido=${numeroPedido}&imprimirDav=S`, {
      responseType: 'arraybuffer',
      headers: { 'Content-Type': 'application/json' }
    });
  }

  async downloadDav(numeroPedido: string) {
    if (!numeroPedido) {
      return '';
    }

    const res = await api.get(`/${PREFIX}/${ROUTE}/getPdfDav?numeroPedido=${numeroPedido}`, {
      responseType: 'arraybuffer',
      headers: { 'Content-Type': 'application/json', Accept: 'application/pdf' }
    });

    // const nomePDF = `${numeroPedido}.pdf`;

    const blob = new Blob([res.data], { type: 'application/pdf' });

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      // const writeFileResult = await Filesystem.writeFile({
      //   data: await blobToBase64(blob),
      //   directory: Directory.Data,
      //   path: nomePDF
      // });
      // await File.writeFile(File.applicationStorageDirectory + '/files', nomePDF, blob, {
      //   replace: true
      // });
      // const path = File.applicationStorageDirectory + '/files/' + nomePDF;
      // FileOpener.open(path, 'application/pdf');
    } else {
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    }

    return;
  }

  async getValorTotalPedidos(params: ReqGetTotalPedidos) {
    try {
      const res = await api.post<number>(`/${PREFIX}/${ROUTE}/getValorTotalPedidos`, {
        params
      });
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }
}

const apiPedido = new ApiPedido();
export default apiPedido;
